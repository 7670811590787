<template>
  <div>
    <blankstate>
      <div class="not-allowed">
        <span class="not-allowed-icon has-text-danger">
          <i class="fas fa-dizzy fa-7x"></i>
        </span>
        <h1 class="not-allowed-desc title is-1 has-text-black-ter">Acesso negado</h1>
        <p class="content is-medium">Você não possui permissão para acessar algumas das requisições executadas</p>
        <button class="button" @click="$router.replace({ name: 'home' })">Ir para página inicial</button>
      </div>
    </blankstate>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Blankstate from '@/components/Blankstate';

export default {
  name: 'NotAllowed',
  inject: ['navbarEventHub'],
  computed: {
    ...mapGetters('auth', ['username']),
  },
  data() {
    return {
      lastRoute: null,
    };
  },
  components: {
    Blankstate,
  },
  watch: {
    username(user) {
      if (user) {
        this.$router.replace(this.lastRoute);
      }
    },
  },
  mounted() {
    this.navbarEventHub.$emit('toggleLogin', true);
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.lastRoute = { ...from };
    });
  },
  beforeRouteLeave(to, from, next) {
    this.navbarEventHub.$emit('toggleLogin', false);
    next();
  },
};
</script>

<style lang="scss" scoped>
.not-allowed {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;

  &-icon {
    margin-bottom: 2em;
  }
}
</style>
