<template>
  <hero>
    <template>
      <div class="header-container">
        <div id="title-container">
          <h4 class="title has-text-weight-bold is-4 has-text-primary">Gerenciar Ambientes</h4>
        </div>
      </div>
      <div>
        <div class="buttons is-right">
          <button class="button is-success" @click="showModalForAddEnvironment()">
            <span class="icon">
              <i class="fa fa-plus"> </i>
            </span>
            <span>Adicionar</span>
          </button>
        </div>
        <table class="table is-fullwidth is-hoverable">
          <thead>
            <tr>
              <th>Nome</th>
              <th>Descrição</th>
              <th>Tipo</th>
              <th>Ação</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(environment, index) in environments" :key="index">
              <td>{{ environment.name }}</td>
              <td>{{ environment.description }}</td>
              <td>{{ environment.type }}</td>
              <td>
                <div class="buttons">
                  <button class="button is-success" @click="showModalForEditEnvironment(environment, index)">
                    <span>Editar</span>
                  </button>
                  <button class="button is-info" @click="enable(environment)" v-if="!environment.enabled">
                    <span>Ativar</span>
                  </button>
                  <button class="button is-danger" @click="disable(environment)" v-if="environment.enabled">
                    <span>Desativar</span>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
    <template v-if="showModal">
      <!-- Add Modal -->
      <div class="modal" :class="{ 'is-active': showModal }">
        <div class="modal-background"></div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title has-text-weight-bold has-text-primary">{{ modalTitle }}</p>
            <button class="delete" aria-label="Fechar" @click="showModal = false"></button>
          </header>
          <!-- Nome -->
          <section class="modal-card-body">
            <div class="field">
              <label class="label">Nome</label>
              <div class="control">
                <input class="input" type="text" v-model="environment.name" placeholder="HML_SGUSUITE" />
              </div>
            </div>
            <!-- Descrição -->
            <div class="field">
              <label class="label">Descrição</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  v-model="environment.description"
                  placeholder="Ambiente de HML SGU SUITE"
                />
              </div>
            </div>
            <!-- Host -->
            <div class="field">
              <label class="label">Host</label>
              <div class="control">
                <input class="input" type="text" v-model="environment.host" placeholder="127.0.0.1:6443" />
              </div>
            </div>
            <!-- Tipo -->
            <div class="field">
              <label class="label">Tipo</label>
              <div class="control">
                <div class="select">
                  <select v-model="environment.type">
                    <option
                      v-bind:value="environmentType"
                      v-for="(environmentType, index) in environmentTypes"
                      :key="index"
                    >
                      {{ environmentType }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <!-- Token -->
            <div class="field">
              <label class="label">Token</label>
              <div class="control">
                <textarea
                  class="textarea"
                  v-model="environment.token"
                  placeholder="737568bc-4f64-435d-b884-f24faa195d50..."
                ></textarea>
              </div>
            </div>
            <!-- Content ... -->
          </section>
          <footer class="modal-card-foot">
            <div class="buttons">
              <button class="button is-success" @click="save()">Salvar</button>
              <button class="button" @click="showModal = false">Cancelar</button>
            </div>
          </footer>
        </div>
      </div>
    </template>
  </hero>
</template>

<script>
import Hero from '@/components/Hero.vue';
import {
  getAllEnvironments,
  disableEnvironment,
  enableEnvironment,
  getEnvironmentById,
  saveEnvironment,
  updateEnvironment,
} from '@/services/api/environmentsService';

const titleAddEnvironment = 'Adicionar Ambiente';
const titleEditEnvironment = 'Editar Ambiente';

export default {
  name: 'environment',
  components: {
    Hero,
  },
  data() {
    return {
      index: 0,
      environment: {},
      environments: [],
      environmentTypes: ['DEV', 'HML', 'PRD'],
      showModal: false,
      modalTitle: titleAddEnvironment,
    };
  },
  async mounted() {
    await this.loadEnvironments();
  },
  methods: {
    async loadEnvironments() {
      this.environments = await getAllEnvironments();
    },
    async disable(environment) {
      disableEnvironment(environment.id);
      environment.enabled = false;
    },
    async enable(environment) {
      enableEnvironment(environment.id);
      environment.enabled = true;
    },
    async showModalForAddEnvironment() {
      this.modalTitle = titleAddEnvironment;
      this.environment = {};
      this.showModal = true;
    },
    async showModalForEditEnvironment(environment, index) {
      this.index = index;
      this.modalTitle = titleEditEnvironment;
      this.environment = await getEnvironmentById(environment.id);
      this.showModal = true;
    },
    async save() {
      if (this.environment.id) {
        await updateEnvironment(this.environment.id, this.environment);
      } else {
        await saveEnvironment(this.environment);
      }
      this.loadEnvironments();
      this.showModal = false;
    },
  },
};
</script>
<style lang="scss" scoped></style>
