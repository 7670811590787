import types from './types';

export default {
  [types.SET_MODULES_VERSIONS](state, modulesVersions) {
    state.modulesVersions = modulesVersions;
  },
  [types.SET_MODULE_INFO](state, moduleInfo) {
    state.currentModule = moduleInfo;
  },
  [types.SET_PUBLISHED_VERSIONS](state, publishedVersions) {
    state.publishedVersions = publishedVersions;
  },
  [types.CONCAT_VERSIONS](state, versions) {
    if (Array.isArray(versions))
      versions.length > 0 && versions.forEach((version) => state.publishedVersions.push(version));
    else state.publishedVersions.push(versions);
  },
  SET_NEXT_VERSION(state, version) {
    state.nextVersion = version;
  },
  SET_ALL_VERSIONS_TO_MODULE(state, versions) {
    state.allVersionsToModule = versions;
  },
};
