<template>
  <hero>
    <rocket-loading v-if="loading" />
    <div v-else>
      <div style="margin-bottom: 1rem">
        <button class="button" @click="$router.go(-1)" data-tooltip="Página anterior" data-tooltip-pos="right">
          <i class="fas fa-arrow-left"></i>
        </button>
      </div>
      <div class="level">
        <div class="level-left">
          <div class="field has-addons title is-4 versions-select">
            <p class="control">
              <a class="button is-static title is-5">
                {{ versionTag }}
              </a>
            </p>
            <span class="control">
              <div class="select title is-5">
                <select v-model="selectedRelease" @change="changeRelease(versionTag, selectedRelease)">
                  <option v-for="(option, index) in releases" :key="index">
                    {{ option }}
                  </option>
                </select>
              </div>
            </span>
          </div>
        </div>
        <div class="level-right has-text-centered">
          <template v-if="enabledEnvironmentUpdate()">
            <button class="button is-success" @click="updateEnvironment">Atualizar</button>
          </template>
          <span style="display: inline-block" v-if="published || refused">
            <div class="finished-version">
              <span
                v-if="published"
                class="tag is-success is-large cursor-default"
                :data-tooltip="`Publicado em: ${publishedDate}`"
              >
                Publicada
              </span>
              <span
                v-else
                class="tag is-danger is-large"
                data-tooltip-pos="left"
                data-tooltip-length="large"
                :data-tooltip="refusalMotive"
              >
                Recusada
              </span>
              <div
                v-if="author"
                :data-tooltip="`${published ? 'Publicado' : 'Recusado'} por: ${author.name}`"
                data-tooltip-pos="up"
                class="cursor-default"
              >
                <user-avatar class="author-avatar" :name="author.name" :email="author.email" />
              </div>
            </div>
          </span>
          <div v-else class="is-flex">
            <span class="tag is-info is-large">Em Homologação</span>
          </div>
        </div>
      </div>
      <div class="message is-info">
        <div class="message-body">
          Uma versão que não foi testada em homologação não poderá ser utilizada no ambiente SAAS e nem utilizada pelos
          clientes
        </div>
      </div>
      <table class="table is-fullwidth">
        <thead>
          <tr>
            <th width="80%">Microserviço</th>
            <th>Tag</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="({ image, description, tag }, index) in services" :key="index">
            <td>
              <div class="version-info">
                <div>
                  <p>{{ description }}</p>
                  <p class="is-size-7">{{ image }}</p>
                </div>
              </div>
            </td>
            <td>
              {{ tag }}
              <div v-if="image == 'docker.fesc.io/sgusuite/spa'">
                <div v-for="({ lib, tag }, index) in spaModules" class="modules-spa-container" :key="index">
                  <div>
                    <span id="module-spa-title"> Modulo: </span>
                    <span>{{ lib }}</span>
                  </div>
                  <div>
                    <span id="module-spa-title"> Tag: </span>
                    <span>{{ tag }}</span>
                  </div>
                  <h1 class="hr-line"></h1>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <spa-modal
      v-if="spaModules"
      :show.sync="showSpaModal"
      :inputModules="spaModules"
      @loadingModulesEvent="loadingModulesUpdate"
    ></spa-modal>

    <plan-update-modal :showModal="this.planUpdateModal" :versionId="versionId" @hideModal="hidePlanUpdateModal">
    </plan-update-modal>
  </hero>
</template>

<script>
import dayjs from 'dayjs';
import semver from 'semver';
import { createNamespacedHelpers } from 'vuex';

import SpaModal from './wizard/spaModal';
import Hero from '@/components/Hero';
import RocketLoading from '@/components/RocketLoading';
import UserAvatar from '@/components/UserAvatar';

import authorize from '@/views/auth/authorize';

import { getUserData } from '@/services/api/userService';
import { getCurrentEnvironmentsModuleVersion } from '@/services/api/environmentsService';
import { getAllServicesToVersion } from '@/services/api/servicesService';
import { checkHotfixActionIsEnable } from '@/services/api/versionsService';

import { cleanVersionStringWithFix } from '@/utils/stringCleaners';

import PlanUpdateModal from '@/views/planupdate/planUpdateModal.vue';

import { getLatestPipeline, getModule, getAllServices, getSpaModuleList } from '@/services/versions';

const { mapGetters, mapActions } = createNamespacedHelpers('versions');

export default {
  name: 'version',
  beforeRouteLeave(to, from, next) {
    this.loop = false;
    next();
  },
  components: {
    Hero,
    RocketLoading,
    SpaModal,
    UserAvatar,
    PlanUpdateModal,
  },
  props: {
    module: [Number, String],
    version: [Number, String],
    versionId: [Number, String],
  },
  data() {
    return {
      versionHML: null,
      versionTag: null,
      releases: null,
      selectedRelease: null,
      publishing: true,
      publishedDate: null,
      services: null,
      loading: true,
      editing: false,
      homologated: false,
      status: null,
      published: null,
      refused: null,
      refusalMotive: null,
      statuses: {
        failed: 'Ambiente não pôde ser atualizado',
        success: 'Ambiente atualizado com sucesso',
        running: 'Atualizando ambiente',
      },
      showSpaModal: null,
      spaModules: null,
      spaLowestVersion: semver('1.0.155-640'),
      author: null,
      moduleId: null,
      loadingModules: false,
      planUpdateModal: false,
    };
  },
  computed: {
    ...mapGetters(['latestVersion', 'allVersionsToModule']),
    pipelineButtonStatus() {
      const status = {
        failed: 'is-danger',
        success: 'is-success',
        running: 'is-info',
        pending: 'is-warning',
      };
      return status[this.status];
    },
    pipelineButtonText() {
      const status = {
        failed: 'Refazer',
        success: 'Atualizado',
        canceled: 'Cancelado',
        running: 'Atualizando',
        pending: 'Iniciando',
        created: 'Iniciando',
        old: 'Atualizar',
        default: 'Atualizar',
      };
      return this.status ? status[this.status] : status.default;
    },
    async hasHotfixAction() {
      const versionIdInt = parseInt(this.versionId, 10);
      const { data: enableHotfixAction } = await checkHotfixActionIsEnable(versionIdInt);

      return enableHotfixAction;
    },
  },
  watch: {
    $route: {
      handler() {
        this.loading = true;
        this.load().then(() => (this.loading = false));
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions(['loadVersions']),
    authorize,
    async load() {
      const module = this.module;
      const { id: moduleId } = await getModule(module);
      this.moduleId = moduleId;

      const [versionWithFix, currentRelease] = this.version.split('-');
      const { version: cleanVersion, fix: currentFix } = cleanVersionStringWithFix(versionWithFix);

      const allVersions = await getAllServices(module, versionWithFix);

      const releases = allVersions
        .filter(({ version, fix }) => fix == currentFix && version === cleanVersion)
        .map(({ release }) => release);
      const [latestRelease] = releases;

      await this.updateSpaModuleList();

      this.loop = true;
      this.releases = releases;
      this.selectedRelease = currentRelease || latestRelease;
      this.versionTag = versionWithFix;

      const currentVersion = allVersions.find(
        ({ version, release, fix }) => version === cleanVersion && release == this.selectedRelease && fix == currentFix,
      );

      this.published = currentVersion && currentVersion.published;
      this.refused = currentVersion && currentVersion.refusalMotive;

      const author = currentVersion.publishedBy || currentVersion.refusedBy;

      if (author) getUserData(author).then(({ data }) => (this.author = data));
      else this.author = null;

      if (this.published) this.publishedDate = dayjs(currentVersion.updatedAt).format('DD/MM/YYYY');

      if (this.refused) this.refusalMotive = currentVersion.refusalMotive;

      await this.statusPipeline();

      const { data: services } = await getAllServicesToVersion({
        moduleId: module,
        version: cleanVersion,
        hotfix: currentFix,
        release: currentRelease,
      });

      this.services = services;
      this.startInterval();
    },
    changeRelease(version, release) {
      const [cleanVersion] = version.split('-');
      const versionToGo = `${cleanVersion}-${release}`;

      this.$router.replace({ path: `${versionToGo}` });
    },
    async statusPipeline() {
      const module = this.module;
      let moduleHMLVersion;
      try {
        const { data: currentEnvironmentsVersion } = await getCurrentEnvironmentsModuleVersion(this.moduleId);
        moduleHMLVersion = currentEnvironmentsVersion[Object.keys(currentEnvironmentsVersion)[0]];
      } catch {
        moduleHMLVersion = null;
      }
      const versionHML = moduleHMLVersion && `${moduleHMLVersion.version}-${moduleHMLVersion.release}`;

      const [cleanVersion, release] = this.version.split('-');
      const { version, fix } = cleanVersionStringWithFix(cleanVersion);

      let { status, id } = await getLatestPipeline(module, `${version}.${fix}-${release ? release : '0'}`);

      if (status === 'created') {
        status = 'pending';
      }
      this.versionHML = versionHML;
      this.publishing = status === 'running' || status === 'pending';
      if (status === 'success' && this.version !== this.versionHML) {
        status = 'old';
      }
      this.status = status;
      this.pipeId = id;
    },
    async startInterval() {
      const sleep = (t) => {
        return new Promise((resolve) => global.setTimeout(resolve, t));
      };
      while (this.loop) {
        await this.statusPipeline();
        await sleep(this.status === 'pending' || this.status === 'running' ? 500 : 3000);
      }
    },
    async updateEnvironment() {
      this.planUpdateModal = true;
    },
    hidePlanUpdateModal() {
      this.planUpdateModal = false;
    },
    validSpaVersion(version) {
      return version && semver(version) >= this.spaLowestVersion;
    },
    async updateSpaModuleList() {
      const spaModules = await getSpaModuleList(this.module, this.version);
      this.spaModules = spaModules.map(({ lib, tag }) => ({ lib, tag }));
    },
    loadingModulesUpdate(event) {
      this.loadingModules = event;
    },
    goToHotfixWizard() {
      const [versionWithFix, release] = this.version.split('-');
      const { version, fix } = cleanVersionStringWithFix(versionWithFix);

      this.$router.push({
        name: 'hotfixWizard',
        params: {
          currentEditing: {
            version,
            fix,
            release,
          },
        },
      });
    },
    enabledEnvironmentUpdate() {
      return authorize('planUpdate:execute') && !this.refused && !this.published;
    },
  },
};
</script>

<style lang="scss" scoped>
.version-content {
  & > * {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}

.version-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.versions-select {
  justify-content: center;
}

.author-avatar {
  height: 40px;
  width: 40px;
  margin-left: 12px;
  font-size: 16px;
}

.finished-version {
  display: flex;
  align-items: center;
}

.new-service {
  cursor: pointer;

  &:hover {
    background: #efefef;
  }
}

.pipe-button {
  height: 2.5em;
  margin-right: 0.6em;
}

.pipe-icon {
  margin-top: 2px;
}

.custon-button {
  min-width: 6rem;
  transition: 0.3s;
}

.loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #fff;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;

  .loader {
    height: 80px;
    width: 80px;
  }

  &.is-active {
    opacity: 1;
    z-index: 1;
  }
}

.custom-button {
  margin-right: 1rem;
  max-height: 2.5rem;
  transition: 0.2s;
}

.custom-button:hover {
  background-color: rgba(201, 44, 83, 0.9);
}

.cursor-default {
  cursor: default;
}

.modules-spa-container {
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;

  div {
    display: flex;
    justify-content: space-between;

    #module-spa-title {
      font-weight: bold;
    }
  }
}

.hr-line {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  &:after {
    content: '';
    background-color: rgb(197, 197, 197);
    height: 1px;
    width: 100%;
  }
}

.tag {
  margin-left: 1rem;
}
</style>
