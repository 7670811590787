<template>
  <div id="app">
    <router-view class="route" />
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style lang="scss">
html {
  overflow: visible !important;
}

#app {
  background: #f1f4f9;
  height: fit-content;
}
@import './themes/bulma-base.scss';
</style>
