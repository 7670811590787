<template>
  <div class="blankstate">
    <div class="columns">
      <div v-if="$slots.left" class="column is-offset-1 is-3 left-content">
        <slot name="left" />
      </div>
      <div class="column" :class="{ 'is-7': $slots.left }">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Blankstate',
};
</script>

<style lang="scss" scoped>
.blankstate {
  border: 1px solid #c9d3dd;
  background-color: #ffffff;
  padding: 75px 45px;

  .left-content {
    justify-content: center;
    display: flex;
  }
}
</style>
