export default () => ({
  modulesVersions: [],
  publishedVersions: [],
  currentModule: {
    id: null,
    name: null,
  },
  nextVersion: '',
  allVersionsToModule: [],
});
