<template>
  <div class="layout-background">
    <section class="layout-main">
      <div class="layout-content">
        <div class="inner-content">
          <slot></slot>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Content',
};
</script>

<style lang="scss" scoped>
@import '~bulma/sass/utilities/all';

.layout-main {
  flex: 1 0 auto;
}

.layout-background {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.layout-content {
  position: relative;
  border: 1px solid #c9d3dd;
  padding: 1rem 3rem 1.25rem;
  background-color: #ffffff;
  height: 100%;
  display: flex;
  flex: 1 0 auto;

  @include mobile {
    padding: 35px 20px;
  }
}

.layout-footer {
  align-self: flex-end;
  flex: 0 0 auto;
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .watermark {
    opacity: 0.5;
    color: #4a4a4a;
    display: flex;
    flex-direction: column;
    text-align: center;
    .logo {
      letter-spacing: 5px;
      transform: scaleY(0.9);
      font-weight: 900;
      text-transform: uppercase;
    }
  }
}

.inner-content {
  width: 100%;
}
</style>
