const login = ({ commit }, authData) => {
  commit('LOGIN', authData);
};

const logout = ({ commit }) => {
  commit('LOGOUT');
};

const setAccessToken = ({ commit }, accessToken) => {
  commit('ACCESS_TOKEN', accessToken);
};

export default {
  login,
  logout,
  setAccessToken,
};
