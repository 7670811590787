import { api } from '@/infra/axios';

export const preFlight = async (planUpdateDTO) => {
  const { data } = await api.post('/plan-update/pre-flight', planUpdateDTO);
  data.allServices = [...data.services, ...data.spaServices].filter(Boolean);
  return data;
};

export const createAndExecute = async (planUpdateDTO) => {
  const { data } = await api.post('/plan-update/execute', planUpdateDTO);
  data.allServices = [...data.services, ...data.spaServices].filter(Boolean);
  return data;
};
