<template>
  <div>
    <template v-if="hasPublishedVersion">
      <hero>
        <div v-if="showLoadingAnimation" class="loading-rocket">
          <rocket-loading :completed="completed"></rocket-loading>
        </div>
        <template v-else>
          <div class="header-container">
            <button class="button" @click="$router.go(-1)" data-tooltip="Página anterior" data-tooltip-pos="right">
              <i class="fas fa-arrow-left"></i>
            </button>
            <div id="title-container">
              <h4 class="title has-text-weight-bold is-4 has-text-primary">
                Gerenciar Versões - <span class="is-uppercase">{{ module }}</span>
              </h4>
            </div>
            <versions-toolbar>
              <button
                @click="gotToGenerateVersionWizard()"
                slot="right"
                class="button nova-versao-button"
                v-if="authorize('version:generate')"
                :disabled="!enableNextVersionGeneration"
                :data-tooltip="!enableNextVersionGeneration && 'Publique ou recuse a última versão para gerar uma nova'"
                data-tooltip-pos="right"
              >
                <p class="subtitle is-5">Gerar nova versão</p>
                <font-awesome-icon :icon="['fa', 'arrow-right']" class="icon" />
              </button>
            </versions-toolbar>
          </div>
          <versions-table
            :module="module"
            :versions="allVersionsToModule"
            @publish="publish($event)"
            @reject="setRejectVersionModal($event, true)"
            @reject-hotfix="setRejectHotfixVersion($event)"
          >
          </versions-table>
        </template>
      </hero>
    </template>
    <no-versions-blankstate v-else :module="module" />
    <reject-version v-if="rejectVersionModal" @close="setRejectVersionModal(false)" @reject="reject($event)">
    </reject-version>
    <modal-confirm
      v-if="showModalConfirm"
      :show.sync="showModalConfirm"
      :actionString="'Publicar nova versão'"
      :completeVersion="this.completeVersion"
      @generateNewVersionEvent="publishConfirmed"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import VersionsToolbar from './VersionsToolbar';
import VersionsTable from './VersionsTable';
import NoVersionsBlankstate from './noVersionsBlankstate';
import RejectVersion from './RejectVersion';
import rocketLoading from '@/components/RocketLoading';
import Hero from '@/components/Hero';
import ModalConfirm from '@/components/ModalConfirm.vue';

import authorize from '@/views/auth/authorize';

import { rejectHotfixVersion, enableNextVersionGeneration } from '@/services/api/versionsService';

const { mapGetters, mapActions } = createNamespacedHelpers('versions');

export default {
  name: 'Versions',
  components: {
    rocketLoading,
    NoVersionsBlankstate,
    Hero,
    VersionsToolbar,
    VersionsTable,
    RejectVersion,
    ModalConfirm,
  },
  props: {
    module: {
      type: [Number, String],
    },
  },
  data() {
    return {
      isLoading: false,
      completed: false,
      showLoadingAnimation: false,
      detailedRow: null,
      rejectVersionModal: false,
      isHotfixReject: false,
      showModalConfirm: false,
      type: null,
      versionIdHotfixReject: null,
      versionId: null,
      enableNextVersionGeneration: false,
      completeVersion: null,
    };
  },
  computed: {
    ...mapGetters(['publishedVersions', 'latestVersion', 'nextVersion', 'allVersionsToModule']),
    hasPublishedVersion() {
      return this.allVersionsToModule.length > 0;
    },
  },
  async created() {
    this.isLoading = true;
    await this.loadVersions(this.module);
    this.isLoading = false;

    this.setNextVersion(this.module);
    await this.loadAllVersionToModule(this.module);

    await this.activeGenerateButton();
  },
  methods: {
    authorize,
    ...mapActions(['loadVersions', 'publishVersion', 'rejectVersion', 'setNextVersion', 'loadAllVersionToModule']),
    openModalConfirmation() {
      this.showModalConfirm = true;
    },
    async publish({ versionId, completeVersion }) {
      this.completeVersion = completeVersion;
      this.versionId = versionId;

      this.openModalConfirmation();
    },
    async publishConfirmed() {
      this.showLoadingAnimation = true;

      await this.publishVersion({
        versionId: this.versionId,
        module: this.module,
        type: this.type,
      });

      await this.activeGenerateButton();

      global.setTimeout(() => {
        this.completed = true;
        global.setTimeout(() => {
          this.showLoadingAnimation = false;
          this.completed = false;
        }, 1000);
      }, 1500);
    },
    async reject(motive) {
      this.showLoadingAnimation = true;

      this.isHotfixReject
        ? await rejectHotfixVersion({
            versionId: this.versionIdHotfixReject,
            motive,
          })
        : await this.rejectVersion({
            versionId: this.versionId,
            module: this.module,
            motive,
          });

      await this.activeGenerateButton();

      global.setTimeout(() => {
        this.completed = true;
        global.setTimeout(() => {
          this.showLoadingAnimation = false;
          this.completed = false;
        }, 1000);
      }, 1500);
    },
    setRejectVersionModal({ versionId }, state) {
      this.versionId = versionId;
      this.isHotfixReject = false;

      this.rejectVersionModal = state;
    },
    setRejectHotfixVersion(versionId) {
      this.rejectVersionModal = true;
      this.versionIdHotfixReject = versionId;

      this.isHotfixReject = true;
    },
    async activeGenerateButton() {
      const { data: enable } = await enableNextVersionGeneration(this.module);

      this.enableNextVersionGeneration = enable;
    },
    gotToGenerateVersionWizard() {
      this.$router.push({
        path: 'version',
        params: {
          versionId: this.versionId,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.nova-versao-button {
  border: none;
  transition: 0.3s;
}

.nova-versao-button p {
  margin-right: 0.5rem;
}

.nova-versao-button:hover {
  color: #000;
}

.icon {
  width: 1rem;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 2rem;

  #title-container {
    display: flex;
    align-items: center;
    margin: 2rem 1rem;
  }
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}

.loader {
  color: #ffffff;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0;
}

.loader:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader:after {
  left: 3.5em;
}

@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

.tag {
  margin-right: 5px;
}
</style>
