import Environment from '@/views/environment/environment';

import Versions from '@/views/versions';
import Version from '@/views/versions/version/wizard/first';
import VersionServices from '@/views/versions/version/version';

import Modules from '@/views/modules';
import Module from '@/views/modules/module';

import Services from '@/views/ServicesView';
import NotAllowed from '@/views/auth/NotAllowed';
import NotFound from '@/views/NotFound';

export default [
  {
    path: '/',
    name: 'home',
    redirect: '/modules',
  },
  {
    name: 'denied',
    path: '/denied',
    component: NotAllowed,
  },
  {
    name: 'environment',
    path: '/environment',
    component: Environment,
  },
  {
    name: 'modules',
    path: 'modules',
    component: Modules,
    meta: { breadcrumb: 'Módulos' },
  },
  {
    name: 'module',
    path: 'module/:module',
    component: Module,
    props: true,
  },
  {
    name: 'versions',
    path: 'modules/:module/versions',
    component: Versions,
    props: true,
    meta: { breadcrumb: 'Versões' },
  },
  {
    name: 'version',
    path: 'modules/:module/version',
    component: Version,
    props: true,
    meta: { breadcrumb: 'Versão' },
  },
  {
    name: 'versionServices',
    path: 'modules/:module/version/:versionId/:version',
    component: VersionServices,
    props: true,
    meta: { breadcrumb: 'Versão' },
  },
  {
    name: 'hotfixWizard',
    path: 'modules/:module/version/:versionId',
    component: Version,
    props: true,
    meta: { breadcrumb: 'Versão', isHotfix: true },
  },
  {
    name: 'services',
    path: 'services',
    component: Services,
    meta: { breadcrumb: 'Serviços' },
  },
  {
    path: '/*',
    name: 'not-found',
    component: NotFound,
  },
];
