<template>
  <div>
    <div class="columns is-multiline">
      <hero class="column is-6" v-for="({ name, description }, index) in modules" :key="index">
        <span
          v-if="modulesCurrentVersion[index]"
          class="tag is-pulled-right has-text-weight-semibold is-rounded is-success"
          :data-tooltip="`Última versão publicada`"
          data-tooltip-pos="up"
        >
          {{ modulesCurrentVersion[index] }}
        </span>
        <h2 class="title is-5 has-text-primary">{{ name.toUpperCase() }}</h2>
        <h3 class="subtitle is-6 has-text-primary">{{ description || '-' }}</h3>
        <router-link
          :to="{ path: `module/${name}` }"
          class="button is-radiusless"
          style="position: absolute; bottom: 0; left: 0; width: 50%"
        >
          Acessar Módulo
        </router-link>
        <button
          @click="$router.push(`modules/${name}/versions`)"
          class="button is-radiusless"
          style="position: absolute; bottom: 0; right: 0; width: 50%"
        >
          Versões
        </button>
      </hero>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { createNamespacedHelpers } from 'vuex';

import Hero from '@/components/Hero.vue';

import { getPublishedVersions } from '@/services/api/versionsService';

const { mapActions, mapGetters } = createNamespacedHelpers('modules');
const { mapGetters: mapAuthGetters } = createNamespacedHelpers('auth');

export default {
  name: 'modules',
  components: {
    Hero,
  },
  data() {
    return {
      dayjs,
      modulesCurrentVersion: [],
    };
  },
  computed: {
    ...mapGetters(['modules']),
    ...mapAuthGetters(['username']),
    activeModules() {
      return this.modules;
    },
  },
  created() {
    this.loadAllModules();
  },
  watch: {
    async modules(modules) {
      if (modules) {
        const currentVersion = [];

        // eslint-disable-next-line no-unused-vars
        for (const { id } of modules) {
          try {
            const { data: publishedVersions } = await getPublishedVersions(id);
            const { version, fix } = publishedVersions?.[publishedVersions.length - 1];

            const lastPublishedVersion = `${version}.${fix}`;

            currentVersion.push(lastPublishedVersion);
          } catch {
            currentVersion.push(null);
          }
        }
        this.modulesCurrentVersion = currentVersion;
      }
    },
  },
  methods: {
    ...mapActions(['loadAllModules']),
    openModule() {
      this.$router.push({ name: 'module' });
    },
  },
};
</script>
<style lang="scss" scoped>
.is-radiusless {
  border-radius: 0;
}
</style>
