import { render, staticRenderFns } from "./RocketLoading.vue?vue&type=template&id=1353c201&scoped=true"
import script from "./RocketLoading.vue?vue&type=script&lang=js"
export * from "./RocketLoading.vue?vue&type=script&lang=js"
import style0 from "./RocketLoading.vue?vue&type=style&index=0&id=1353c201&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1353c201",
  null
  
)

export default component.exports