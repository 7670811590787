import types from './types';

export default {
  [types.SET_MODULE](state, currentModule) {
    const index = state.modules.findIndex((module) => {
      return currentModule.id === module.id;
    });

    if (index > -1) {
      state.modules[index] = currentModule;
    } else {
      state.modules.push(currentModule);
    }
  },
  [types.SET_ALL_MODULES](state, modules) {
    state.modules = modules;
  },
};
