<template>
  <blankstate>
    <rocket-loading slot="left" />
    <template slot>
      <h4 class="title has-text-weight-bold is-4 has-text-primary">Crie e Gerencie Versões</h4>
      <p class="has-text-primary">
        Você ainda não possui nenhuma versão gerada para o <b> {{ module.toUpperCase() }}</b
        >. Clique no botão abaixo para gerar a primeira versão para este módulo.
      </p>
      <br />
      <button
        @click="$router.push({ name: 'version' })"
        :disabled="!authorize('version:generate')"
        class="button is-success is-size-7 has-text-weight-semibold"
      >
        Gerar Nova Versão
      </button>
    </template>
  </blankstate>
</template>

<script>
import Blankstate from '@/components/Blankstate';
import rocketLoading from '@/components/RocketLoading';
import authorize from '@/views/auth/authorize';

export default {
  name: 'notFoundBlankstate',
  components: {
    Blankstate,
    rocketLoading,
  },
  props: {
    module: [Number, String],
    version: [Number, String],
  },
  methods: {
    authorize,
  },
};
</script>
