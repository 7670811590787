import resolvers from './resolvers';

export default {
  publishedVersions: (state) => resolvers.publishedVersions(state.publishedVersions),
  latestVersion: (state) => state.publishedVersions[state.publishedVersions.length - 1],
  moduleInfo: (state) => resolvers.moduleInfo(state.currentModule),
  modulesVersions: (state) => state.modulesVersions,
  nextVersion: (state) => state.nextVersion,
  allVersionsToModule: (state) => state.allVersionsToModule,
};
