import Vue from 'vue';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import utc from 'dayjs/plugin/utc';
import store from './store';
import router from './router/index';
import App from './App.vue';
import 'dayjs/locale/pt-br';
import './themes/external-icons';

dayjs.locale('pt-br');
dayjs.extend(localeData);
dayjs.extend(customParseFormat);
dayjs.extend(utc);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
