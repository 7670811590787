import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import persistedState from 'vuex-persistedstate';

import auth from './auth';
import environments from './environments';
import modules from './modules';
import versions from './versions';

Vue.use(Vuex);

export default new Store({
  strict: process.env.NODE_ENV !== 'production',
  modules: {
    auth,
    versions,
    modules,
    environments,
  },
  plugins: [
    persistedState({
      key: 'state',
      paths: ['auth'],
    }),
  ],
});
