<template>
  <div class="modal" :class="{ 'is-active': show }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Módulos do SPA</p>
        <button
          v-if="editEnabled"
          @click="editing ? save() : edit()"
          class="button is-primary is-pulled-right"
          :disabled="editing && !allowsInsert"
          style="margin-right: 1em"
        >
          {{ editing ? 'Salvar' : 'Editar' }}
        </button>
        <button class="delete" aria-label="close" @click="$emit('update:show', false)"></button>
      </header>
      <section class="modal-card-body">
        <template v-if="loadingCount">
          <div class="loading" style="padding: 4em; overflow: hidden"></div>
        </template>
        <template v-else-if="modules.length === 0"> Nenhum módulo definido neste SPA </template>
        <template v-else>
          <table class="table is-fullwidth">
            <thead>
              <tr>
                <th width="70%">Módulo</th>
                <th>Tag</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(spaModule, index) in modules" :key="index">
                <td v-if="spaModule.lib">
                  <span class="tag is-success" v-if="!spaModule.ogTag">Novo</span>
                  {{ spaModule.lib }}
                </td>
                <td v-else>
                  <div class="select is-fullwidth">
                    <select v-model="spaModule.lib" @change="changeModule(index, $event)">
                      <option v-for="option in availableModules" :key="option">{{ option }}</option>
                    </select>
                  </div>
                </td>
                <td v-if="editEnabled">
                  <div class="field has-addons">
                    <div class="control">
                      <div class="select is-fullwidth">
                        <select
                          v-model="spaModule.tag"
                          @change="changeModuleTag(index, $event)"
                          :disabled="!editing || !spaModule.tags"
                        >
                          <option v-for="option in spaModule.tags" :key="option">{{ option }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="control">
                      <span v-if="editing" @click="removeModule(index)" class="icon is-medium" style="cursor: pointer">
                        <i class="fas fa-times" />
                      </span>
                    </div>
                  </div>
                </td>
                <td v-else>
                  {{ spaModule.tag }}
                </td>
              </tr>
            </tbody>
          </table>
        </template>
      </section>
      <footer
        v-if="editing"
        class="modal-card-foot module-insert"
        :class="{ 'allow-insert': allowsInsert }"
        @click="allowsInsert && newModule()"
      >
        <span class="has-text-weight-bold"> <i class="fas fa-plus"></i> Adicionar novo </span>
      </footer>
    </div>
  </div>
</template>

<script>
import { getSpaDependenciesList, getNpmModuleVersions } from '@/services/versions';

export default {
  name: 'SpaModal',
  props: {
    show: Boolean,
    inputModules: Array,
    editEnabled: Boolean,
  },
  computed: {
    availableModules() {
      const currentModules = this.modules.map(({ lib }) => lib);

      return this.moduleList.filter((lib) => !currentModules.includes(lib));
    },
    allowsInsert() {
      const modulesLength = this.modules.length;

      if (modulesLength === 0) return true;

      const latestModule = this.modules[modulesLength - 1];

      return latestModule.lib && latestModule.tag;
    },
  },
  data() {
    return {
      editing: false,
      modules: [],
      moduleList: [],
      insertStart: 0,
      loadingCount: 0,
    };
  },
  async created() {
    this.updateModulesValues();
  },
  watch: {
    inputModules: {
      handler() {
        this.clearModules();
        this.updateModulesValues();
      },
    },
  },
  methods: {
    edit() {
      this.editing = true;
    },
    save() {
      this.editing = false;
      this.insertStart = this.modules.length;
      this.$emit('newModules', this.modules);
    },
    newModule() {
      this.modules.push({});
    },
    removeModule(index) {
      this.modules.splice(index, 1);
    },
    async changeModule(index, event) {
      const newModule = event.target.value;

      this.$set(this.modules[index], 'lib', newModule);

      const moduleTags = await getNpmModuleVersions(newModule);
      this.modules[index].tags = moduleTags;
      this.$forceUpdate();
    },
    changeModuleTag(index, event) {
      const newTag = event.target.value;

      this.$set(this.modules[index], 'tag', newTag);
    },
    async updateModulesValues() {
      this.$emit('loadingModulesEvent', true);

      this.insertStart = this.inputModules.length;
      this.loadingCount = this.inputModules.length;

      await this.setModules();

      this.moduleList = await getSpaDependenciesList();

      this.$emit('loadingModulesEvent', false);
    },
    async setModules() {
      return new Promise((resolve) => {
        this.inputModules.forEach(async ({ lib, tag }, index) => {
          await getNpmModuleVersions(lib).then((data) => {
            this.modules.push({ lib, tag, ogTag: tag, tags: data });
            --this.loadingCount;

            if (index === this.inputModules.length - 1) resolve();
          });
        });
      });
    },
    clearModules() {
      this.modules = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.module-insert {
  padding: 0.7em 20px;
  cursor: not-allowed;

  &.allow-insert {
    cursor: pointer;

    &:hover {
      background: #efefef;
    }
  }
}
</style>
