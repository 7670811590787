<template>
  <div class="modal" :class="{ 'is-active': show }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Tem certeza que deseja {{ nextVersion ? 'gerar' : 'publicar' }} uma nova versão?</p>
        <button class="delete" aria-label="close" @click="$emit('update:show', false)"></button>
      </header>
      <section class="modal-card-body">
        <h6 class="subtitle is-6">
          Antes de prosseguir verifique se as informações que foram configuradas estão corretas.
        </h6>

        <div class="content is-large">
          <h5 class="title is-5">Versão {{ nextVersion ? 'atual' : 'que será publicada' }}</h5>
          <h6 class="subtitle is-6">{{ versionToConfirm }}</h6>
          <div v-if="nextVersion">
            <h5 class="title is-5 mt-1">Próxima versão</h5>
            <h6 class="subtitle is-6">{{ nextVersion }}</h6>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <div>
          <h6>
            Para prosseguir confirme a versão que deve ser
            {{ nextVersion ? 'gerada.' : 'publicada.' }}
          </h6>
        </div>
        <div class="action-buttons">
          <input
            v-model="inputVersion"
            :class="`input is-focused ${!this.versionConfimation() && 'is-danger'} `"
            type="text"
            placeholder="0.0.0.0-0"
          />
          <button class="button is-success" @click="confirmEvent" :disabled="!this.versionConfimation()">
            {{ actionString }}
          </button>
          <button class="button" @click="cancelEvent">Cancelar</button>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapGetters } = createNamespacedHelpers('versions');

export default {
  name: 'ModalConfirm',
  props: {
    show: Boolean,
    version: String,
    nextVersion: String,
    release: String,
    actionString: String,
    completeVersion: String,
  },
  data: () => ({
    inputVersion: '',
    versionToConfirm: '',
  }),
  computed: {
    ...mapGetters(['allVersionsToModule']),
  },
  created() {
    if (!this.completeVersion) {
      const [latestVersion] = this.allVersionsToModule;

      this.versionToConfirm = !this.version
        ? `${latestVersion.version}.${latestVersion.fix}-${latestVersion.releaseCandidate.toString()}`
        : this.version;
    } else this.versionToConfirm = this.completeVersion;
  },
  methods: {
    cancelEvent() {
      this.$emit('update:show', false);
    },
    confirmEvent() {
      this.$emit('update:show', false);

      this.$emit('generateNewVersionEvent');
    },
    versionConfimation() {
      return this.inputVersion === (this.nextVersion ? this.nextVersion : this.versionToConfirm);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-card-foot {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  input {
    width: 18rem;
  }

  .action-buttons {
    display: flex;
    width: 100%;

    input {
      width: 100%;
      margin-right: 0.5rem;
    }
  }

  h6 {
    white-space: nowrap;
    margin-bottom: 0.5rem;
  }
}

.mt-1 {
  margin-top: 1rem !important;
}
</style>
