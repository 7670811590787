import getProperty from '@/utils/getProperty';

const moduleInfo = (moduleInfo) => {
  const get = getProperty(moduleInfo);
  const name = get('name');
  const uppercaseName = name && name.toUpperCase();

  return {
    id: get('id'),
    name: uppercaseName,
    description: get('description'),
  };
};

const publishedVersions = (publishedVersions) => {
  return publishedVersions.map((version) => ({
    published: version.published,
    version: version.version,
    release: version.release,
    id: version.id,
    fix: version.fix,
    refusalMotive: version.refusalMotive,
    updatedAt: version.updatedAt,
    publishedBy: version.publishedBy,
  }));
};

export default { moduleInfo, publishedVersions };
