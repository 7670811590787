import types from './types';

import {
  getAllVersions,
  getPublishedVersions,
  publishVersion as publishVersionApiService,
  rejectVersion as rejectVersionApiService,
  getNextVersion,
} from '@/services/api/versionsService';
import { getAllServices } from '@/services/versions';

const loadModulesVersions = async ({ commit }, modules) => {
  const modulesVersions = [];

  // eslint-disable-next-line no-unused-vars
  for (const { id } of modules) {
    const { data: versions } = await getAllVersions(id);

    modulesVersions.push(...versions);
  }

  commit(types.SET_MODULES_VERSIONS, modulesVersions);
};

const loadVersions = async ({ commit, dispatch }, module) => {
  await getAllVersions(module).then(({ data: allVersions }) => commit('SET_ALL_VERSIONS_TO_MODULE', allVersions));

  await dispatch('loadPublishedVersions', module);
};

const loadPublishedVersions = async ({ commit }, module) => {
  await getPublishedVersions(module).then(({ data: publishedVersions }) => {
    commit(types.SET_PUBLISHED_VERSIONS, publishedVersions);
  });
};

const publishVersion = async ({ dispatch }, { versionId, module, type }) => {
  const postData = {
    minor: type === 'minor',
  };

  await publishVersionApiService(versionId, postData).then(() => dispatch('loadVersions', module));
};

const rejectVersion = async ({ dispatch }, { versionId, module, motive }) => {
  await rejectVersionApiService({ versionId, motive }).then(() => dispatch('loadVersions', module));
};

const setNextVersion = async ({ commit }, module) => {
  const { data } = await getNextVersion(module);
  commit('SET_NEXT_VERSION', data);
};

const updateNextVersion = ({ commit }, version) => {
  commit('SET_NEXT_VERSION', version);
};

const loadAllVersionToModule = async ({ commit }, moduleId) => {
  const allVersions = await getAllServices(moduleId);
  commit('SET_ALL_VERSIONS_TO_MODULE', allVersions);
};

export default {
  loadModulesVersions,
  loadPublishedVersions,
  publishVersion,
  loadVersions,
  rejectVersion,
  updateNextVersion,
  setNextVersion,
  loadAllVersionToModule,
};
