import Vue from 'vue';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowRight, faArrowsAlt } from '@fortawesome/free-solid-svg-icons';

library.add(faArrowRight, faArrowsAlt);

Vue.component('font-awesome-icon', FontAwesomeIcon);
