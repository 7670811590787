<template>
  <div>
    <blankstate>
      <div class="not-found">
        <span class="not-found-icon">
          <i class="fas fa-exclamation-triangle fa-7x"></i>
        </span>
        <h1 class="not-found-desc title is-1 has-text-black-ter">Página inexistente</h1>
        <p class="content is-medium">A rota que você acessou não foi encontrada</p>
        <button class="button" @click="$router.replace({ name: 'home' })">Ir para página inicial</button>
      </div>
    </blankstate>
  </div>
</template>

<script>
import Blankstate from '@/components/Blankstate';

export default {
  name: 'NotFound',
  components: {
    Blankstate,
  },
};
</script>

<style lang="scss" scoped>
.not-found {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;

  &-icon {
    margin-bottom: 2em;
  }
}
</style>
