<template>
  <nav class="level is-mobile full-width">
    <div class="level-left">
      <div class="level-item">
        <slot></slot>
        <slot name="left"></slot>
      </div>
    </div>
    <div v-if="$slots.center" class="level-item">
      <slot name="center"></slot>
    </div>
    <div class="level-right">
      <div v-if="$slots.right" class="level-item">
        <slot name="right"></slot>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'VersionsToolbar',
};
</script>

<style scoped>
.full-width {
  flex: auto;
}
</style>
