import { api } from '@/infra/axios';

export const getAllServicesToVersion = async ({ moduleId, version, hotfix, release = '' }) => {
  const releaseToUrl = release && release !== null ? `-${release}` : '';

  return api.get(`/modules/${moduleId}/versions/${version}.${hotfix}${releaseToUrl}/services`);
};

export const setService = async (service) => {
  return api.post('services', service);
};

export const getAllVersionServices = async () => {
  return api.get(`/services`);
};
