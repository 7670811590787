<template>
  <div class="user-avatar">
    <span class="has-background-info has-text-white user-avatar__default">
      <span style="opacity: 0.8; font-weight: bold">
        {{ name.toUpperCase().charAt(0) }}
      </span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'UserAvatar',
  props: {
    name: String,
    email: String,
  },
};
</script>

<style scoped>
.user-avatar {
  border-radius: 10000px;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.user-avatar .user-avatar__default {
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  display: flex;
}
</style>
