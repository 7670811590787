import { set } from 'vue';

export default {
  ['LOGIN'](state, authData) {
    state.accessToken = authData.accessToken;
    state.refreshToken = authData.refreshToken;

    set(state, 'user', authData.user);
    set(state, 'scope', authData.scope);
    set(state, 'email', authData.email);
  },
  ['LOGOUT'](state) {
    state.accessToken = null;
    state.refreshToken = null;
    state.user = null;
    state.scope = null;
  },
  ['ACCESS_TOKEN'](state, accessToken) {
    state.accessToken = accessToken;
  },
};
