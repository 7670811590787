<template>
  <div class="loading-rocket">
    <div>
      <div class="popup">
        <div class="rocket">
          <div class="sky">
            <div class="star star1"></div>
            <div class="star star2"></div>
            <div class="star star3"></div>
            <div class="star star4"></div>
            <div class="star star5"></div>
            <div class="star star6"></div>
            <div class="star star7"></div>
            <div class="star star8"></div>
            <div class="star star9"></div>
            <div class="star star10"></div>
            <div class="star star11"></div>
            <div class="star star12"></div>
            <div class="star star13"></div>
            <div class="star star14"></div>
            <div class="star star15"></div>
            <div class="star star16"></div>
          </div>
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 500 556.726"
            style="enable-background: new 0 0 500 556.726"
            xml:space="preserve"
          >
            <g>
              <g>
                <defs>
                  <rect id="SVGID_1_" x="45" y="60.387" width="409.92" height="410.88" />
                </defs>
                <clipPath id="SVGID_2_">
                  <use xlink:href="#SVGID_1_" style="overflow: visible" />
                </clipPath>
              </g>
              <g>
                <defs>
                  <rect id="SVGID_3_" x="45" y="60.387" width="409.92" height="410.88" />
                </defs>
                <clipPath id="SVGID_4_">
                  <use xlink:href="#SVGID_3_" style="overflow: visible" />
                </clipPath>
                <path
                  style="clip-path: url(#SVGID_4_)"
                  fill="#fff"
                  d="M343.238,172.072c-11.457-11.457-26.687-17.766-42.89-17.766
          c-16.203,0-31.438,6.309-42.895,17.766c-11.457,11.457-17.765,26.691-17.765,42.894s6.308,31.434,17.765,42.891
          c11.457,11.457,26.692,17.766,42.895,17.766c16.203,0,31.433-6.309,42.89-17.766C366.887,234.205,366.887,195.724,343.238,172.072
          L343.238,172.072z M329.324,243.947c-7.738,7.738-18.031,12.004-28.976,12.004c-10.95,0-21.243-4.266-28.981-12.004
          c-7.742-7.742-12.004-18.035-12.004-28.981c0-10.949,4.262-21.238,12.004-28.98c7.738-7.742,18.031-12.004,28.981-12.004
          c10.945,0,21.238,4.262,28.976,12.004C345.305,201.966,345.305,227.966,329.324,243.947L329.324,243.947z M329.324,243.947"
                />
                <path
                  class="morph"
                  style="clip-path: url(#SVGID_4_)"
                  d="M447.742,74.537c-0.906-3.403-3.562-6.055-6.961-6.965
          c-33.64-8.992-69.254-9.141-102.984-0.43c-5.258,1.359-10.438,2.957-15.555,4.731c-0.441,0.121-0.879,0.273-1.301,0.457
          c-28.089,9.964-53.777,26.113-75.066,47.339c-0.016,0.012-0.027,0.024-0.035,0.036c-0.059,0.054-0.113,0.109-0.168,0.164
          c-0.09,0.09-0.168,0.187-0.254,0.277c-0.082,0.09-0.172,0.172-0.254,0.266l-30.129,35.07
          c-47.402-0.523-93.465,18.203-127.051,51.789c-10.922,10.922-20.343,23.16-28.007,36.379c-1.547,2.668-1.754,5.906-0.559,8.746
          c1.195,2.844,3.652,4.961,6.641,5.719l54.679,13.922l-10.699,29.054c-5.285,14.348-1.711,30.606,9.102,41.418l53.668,53.668
          c7.496,7.492,17.601,11.512,27.882,11.512c4.551,0,9.133-0.789,13.536-2.41l29.058-10.699l13.922,54.679
          c0.758,2.989,2.879,5.45,5.719,6.645c1.222,0.512,2.519,0.765,3.812,0.765c1.711,0,3.414-0.445,4.934-1.328
          c13.215-7.66,25.453-17.082,36.379-28.004c33.59-33.589,52.308-79.664,51.793-127.058l35.062-30.129
          c0.09-0.078,0.172-0.168,0.258-0.246c0.098-0.09,0.195-0.168,0.289-0.262c0.055-0.055,0.106-0.113,0.164-0.168
          c0.008-0.012,0.024-0.023,0.031-0.035c21.231-21.285,37.375-46.977,47.344-75.063c0.18-0.425,0.336-0.863,0.457-1.308
          c1.774-5.113,3.367-10.293,4.727-15.551C456.887,143.787,456.738,108.177,447.742,74.537L447.742,74.537z M84.086,242.404
          c5.277-7.543,11.238-14.645,17.809-21.219c25.726-25.726,59.695-41.629,95.558-45.242l-67.203,78.215L84.086,242.404z
          M128.496,307.888l9.156-24.863l12.692,12.687l-19.18,30.696C127.262,321.138,126.191,314.15,128.496,307.888L128.496,307.888z
          M145.324,340.873l19.297-30.883l13.399,13.394l-25.094,25.09L145.324,340.873z M166.84,362.388l25.09-25.09l13.394,13.395
          l-30.883,19.297L166.84,362.388z M207.426,386.818c-6.262,2.305-13.246,1.234-18.52-2.668l30.696-19.18l12.691,12.692
          L207.426,386.818z M294.137,413.427c-6.575,6.571-13.68,12.532-21.223,17.805l-11.754-46.168l78.219-67.203
          C335.766,353.724,319.863,387.697,294.137,413.427L294.137,413.427z M381.746,255.517L250.629,368.173L147.141,264.689
          l79.586-92.625c0.078-0.086,0.152-0.176,0.226-0.266l32.844-38.226c17.902-17.84,39.246-31.699,62.562-40.785l100.168,100.172
          C413.445,216.275,399.586,237.619,381.746,255.517L381.746,255.517z M429.293,171.9l-85.875-85.879
          c28.391-7.211,58.23-7.496,86.715-0.84C436.789,113.666,436.508,143.505,429.293,171.9L429.293,171.9z M429.293,171.9"
                />
              </g>

              <line
                class="fire first"
                style="stroke-width: 20; stroke-linecap: round; stroke-linejoin: round; stroke-miterlimit: 10"
                x1="150.723"
                y1="422.976"
                x2="120.381"
                y2="454.03"
              />

              <line
                class="fire second"
                style="stroke-width: 20; stroke-linecap: round; stroke-linejoin: round; stroke-miterlimit: 10"
                x1="122.667"
                y1="391.825"
                x2="49.821"
                y2="466.381"
              />

              <line
                class="fire third"
                style="stroke-width: 20; stroke-linecap: round; stroke-linejoin: round; stroke-miterlimit: 10"
                x1="92.664"
                y1="363.96"
                x2="71.825"
                y2="385.289"
              />
            </g>
          </svg>
        </div>
      </div>

      <div class="check" :class="{ active: completed }">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          preserveAspectRatio="xMidYMid"
          width="61"
          height="52"
          viewBox="0 0 61 52"
          class="check-icon"
        >
          <path
            d="M56.560,-0.010 C37.498,10.892 26.831,26.198 20.617,33.101 C20.617,33.101 5.398,23.373 5.398,23.373 C5.398,23.373 0.010,29.051 0.010,29.051 C0.010,29.051 24.973,51.981 24.973,51.981 C29.501,41.166 42.502,21.583 60.003,6.565 C60.003,6.565 56.560,-0.010 56.560,-0.010 Z"
            id="path-1"
            class="cls-2"
            fill-rule="evenodd"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    completed: Boolean,
  },
};
</script>

<style lang="scss" scoped>
$size-icon: 136px;

.loading-rocket {
  align-items: center;
  justify-content: center;
  display: flex;
}

.popup {
  background: #05293e;
  border-radius: 100px;
  width: $size-icon;
  height: $size-icon;
  position: relative;
}
.rocket {
  position: absolute;
  margin: 0 auto;
  left: 40px;
  top: 35px;
  animation: vibrate 0.3s ease infinite;
  svg {
    width: 60px;
    fill: #fff;
    .fire {
      transition: all ease-in-out 1s;
      stroke: #fff;
      &.first {
        stroke-dasharray: 100;
        stroke-dashoffset: 0;

        animation: dash 0.2s ease infinite;
      }
      &.second {
        stroke-dasharray: 200;
        stroke-dashoffset: 0;
        animation: dash2 0.2s ease infinite;
      }
      &.third {
        stroke-dasharray: 85;
        stroke-dashoffset: 0;

        animation: dash 0.2s ease infinite;
      }
    }
  }
}
.sky {
  transform: rotate(45deg);
}
.star {
  position: absolute;
  width: 4px;
  height: 10px;
  background: #fff;
  border-radius: 25px;
  z-index: 2;
  animation: skyspace 0.5s ease infinite;
  &.star1 {
    right: -60px;
    top: -30px;
    animation-delay: 0.1s;
  }
  &.star2 {
    right: 30px;
    top: 30px;
    animation-delay: 0.1s;
  }
  &.star3 {
    top: -40px;
    left: 45px;
    animation-delay: 0.2s;
  }
  &.star4 {
    top: -20px;
    right: 75px;
    animation-delay: 0.2s;
  }
  &.star5 {
    right: -35px;
    top: -60px;
    animation-delay: 0.3s;
  }
  &.star6 {
    right: 160px;
    top: 50px;
    animation-delay: 0.4s;
  }
  &.star7 {
    top: 20px;
    left: 75px;
    animation-delay: 0.3s;
  }
  &.star8 {
    top: -30px;
    right: 95px;
    animation-delay: 0.4s;
  }
  &.star9 {
    right: 30px;
    top: -60px;
    animation-delay: 0.3s;
  }
  &.star10 {
    right: 160px;
    top: 50px;
    animation-delay: 0.4s;
  }
  &.star11 {
    top: 20px;
    left: 75px;
    animation-delay: 0.3s;
  }
  &.star12 {
    top: -30px;
    right: 95px;
    animation-delay: 0.4s;
  }

  &.star13 {
    right: -90px;
    top: -100px;
    animation-delay: 0.3s;
  }
  &.star14 {
    right: -20px;
    top: 50px;
    animation-delay: 0.4s;
  }
  &.star15 {
    top: 20px;
    right: -65px;
    animation-delay: 0.3s;
  }
  &.star16 {
    top: -30px;
    right: -25px;
    animation-delay: 0.4s;
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 50;
  }
  50% {
    stroke-dashoffset: 70;
  }
  80% {
    stroke-dashoffset: 60;
  }
  100% {
    stroke-dashoffset: 50;
  }
}
@-webkit-keyframes dash2 {
  0% {
    stroke-dashoffset: 100;
  }
  50% {
    stroke-dashoffset: 120;
  }
  80% {
    stroke-dashoffset: 110;
  }
  100% {
    stroke-dashoffset: 100;
  }
}

@keyframes vibrate {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(-1px, 1px);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes skyspace {
  0% {
    transform: translate(0, -200px) scale(1, 0);
    opacity: 0.8;
  }
  100% {
    transform: translate(0, 300px) scale(1, 2);
    opacity: 0;
  }
}

*,
*:before,
*:after {
  box-sizing: border-box;
}
.check {
  background: trasparent;
  width: $size-icon;
  height: $size-icon;
  transform: translate(0%, -$size-icon);
  border-radius: 50%;
  position: absolute;
  box-shadow: 0px -4px 4px 0px rgba(255, 255, 255, 0.75), inset 0px 4px 4px 0px rgba(0, 0, 0, 0.08);

  &:before {
    content: '';
    position: absolute;
    border-radius: 50%;
    top: 9%;
    left: 9%;
    right: 9%;
    bottom: 9%;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.08);
  }

  &:after {
    content: '';
    position: absolute;
    border-radius: 50%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-color: #60b247;
    border-width: 0;
    border-style: solid;
  }

  .check-icon {
    position: absolute;
    z-index: 3;
    top: 31%;
    left: 28%;
    width: 45%;
    fill: transparent;
  }

  &.active {
    &:after {
      transition: 0.2s ease-out all;
      border-width: ($size-icon / 2);
    }

    .check-icon {
      fill: #fff;
      animation: bounce 0.6s;
      animation-delay: 0.08s;
    }
  }
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
</style>
