import { api } from '@/infra/axios';

export const setTagInGitlab = async (moduleId, version) => {
  return api.post(`/gitlab/${moduleId}/tags/${version}`);
};

export const setPipelineInGitlab = async (moduleId, version) => {
  return api.post(`/gitlab/${moduleId}/pipelines/${version}`);
};

export const getLatestPipelineInGitlab = async (moduleId, version) => {
  return api.get(`/gitlab/${moduleId}/pipelines/${version}/latest`);
};

export const getSpaDependenciesListInGitlab = async () => {
  return api.get(`/gitlab/spa`);
};
