<template>
  <div class="modal" :class="{ 'is-active': show }">
    <div class="modal-background"></div>
    <form @submit.prevent="submit" @keyup.esc="close" class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Login</p>
        <button type="button" class="delete" aria-label="close" @click="close"></button>
      </header>
      <section class="modal-card-body">
        <div class="field">
          <label class="help is-danger has-text-centered" v-if="errorMessage">{{ errorMessage }}</label>
        </div>
        <div class="field">
          <label class="label">Usuário</label>
          <div class="control">
            <input ref="user" class="input" :class="{ 'is-danger': error.credential }" type="text" v-model="user" />
          </div>
        </div>
        <div class="field">
          <label class="label">Senha</label>
          <div class="control">
            <input class="input" :class="{ 'is-danger': error.credential }" type="password" v-model="pass" />
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-success" type="submit" :disabled="loading">Entrar</button>
        <button class="button" type="button" @click="close">Cancelar</button>
      </footer>
    </form>
  </div>
</template>

<script>
import { authentication } from '@/services/api/authService';

export default {
  name: 'Login',
  props: {
    show: Boolean,
  },
  data() {
    return {
      user: '',
      pass: '',
      loading: false,
      error: {},
    };
  },
  computed: {
    errorMessage() {
      if (this.error.credential) {
        return 'Usuário ou senha inválidos';
      }
      if (this.error.server) {
        return 'Erro no servidor';
      }

      return '';
    },
  },
  watch: {
    show(value) {
      if (value) {
        this.$nextTick(() => this.$refs.user.focus());
      }
    },
  },
  methods: {
    close() {
      this.$emit('update:show', false);
    },
    submit() {
      if (this.user && this.pass) {
        this.loading = true;

        authentication(this.user, this.pass)
          .then(({ data }) => {
            const auth = {
              accessToken: data.access_token,
              refreshToken: data.refresh_token,
              user: data.user,
              email: this.user,
            };
            this.error = {};
            this.user = '';
            this.pass = '';

            this.$store.dispatch('auth/login', auth);
            this.close();
          })
          .catch((err) => {
            if (err.response.status === 400) this.error = { credential: true };
            else this.error = { server: true };
          })
          .finally(() => (this.loading = false));
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
