import { api } from '@/infra/axios';

export const getAllModules = async () => {
  return api.get(`/modules`);
};

export const getModule = async (moduleId) => {
  return api.get(`/modules/${moduleId}`);
};

export const updateModule = async (moduleId, module) => {
  return api.put(`/modules/${moduleId}`, module);
};

export const getSpaModuleList = async (moduleId, version) => {
  return api.get(`/modules/${moduleId}/versions/${version}/spa`);
};

export const setSpaModules = async (moduleId, spaModules) => {
  return api.post(`/modules/${moduleId}/spa`, spaModules);
};
