<template>
  <transition name="fade-app" mode="out-in">
    <div class="app" v-if="show">
      <header-application :event-hub="navbarEventHub" />
      <div :class="fullwidth ? 'fullwidth-container' : 'container'">
        <transition name="fade" mode="out-in">
          <router-view class="router"></router-view>
        </transition>
      </div>
      <footer-application />
    </div>
  </transition>
</template>

<script>
import Vue from 'vue';
import FooterApplication from './Footer';
import HeaderApplication from './Header';

export default {
  name: 'Application',
  components: {
    HeaderApplication,
    FooterApplication,
  },
  data() {
    return {
      show: false,
      menu: [],
      navbarEventHub: new Vue(),
      fullwidth: false,
    };
  },
  mounted() {
    this.show = true;
  },
  watch: {
    $route: {
      handler(route) {
        this.fullwidth = route.meta.fullwidth;
      },
      immediate: true,
    },
  },
  provide() {
    return {
      navbarEventHub: this.navbarEventHub,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '~bulma/sass/utilities/all';

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-app-enter-active,
.fade-app-leave-active {
  transition: opacity 0.3s ease;
}

.fade-app-enter,
.fade-app-leave-to {
  opacity: 0;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.container {
  padding: 20px 10% 0;
  flex-grow: 1;
  width: 100%;

  @include mobile {
    padding: 10px;
  }
}

.fullwidth-container {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  padding-top: 20px;
}
</style>
