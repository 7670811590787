import dayjs from 'dayjs';

import types from './types';
import store from '@/store';

import { getEnvironmentsUpdates } from '@/services/api/environmentsService';

const loadModulesHMLVersion = async ({ commit }) => {
  const { data: environmentsUpdates } = await getEnvironmentsUpdates();

  const modulesVersions = store.getters['versions/modulesVersions'].reduce((result, version) => {
    result[version.id] = version;

    return result;
  }, {});

  let modulesHMLVersion = environmentsUpdates.reduce((result, update) => {
    const version = modulesVersions[update.versionId];

    if (!result[version.moduleId]) {
      result[version.moduleId] = update;
    } else {
      const currentDate = dayjs(result[version.moduleId].updatedAt || result[version.moduleId].createdAt);
      const newDate = dayjs(update.updatedAt || update.createdAt);

      if (newDate.isAfter(currentDate)) {
        result[version.moduleId] = update;
      }
    }

    return result;
  }, {});
  modulesHMLVersion = Object.keys(modulesHMLVersion).reduce((result, module) => {
    result[module] = modulesVersions[modulesHMLVersion[module].versionId];

    return result;
  }, {});

  commit(types.SET_MODULES_HML_VERSION, modulesHMLVersion);
};

export default {
  loadModulesHMLVersion,
};
