<template>
  <div>
    <div class="columns">
      <hero class="column is-4 is-fullheight">
        <div class="logo has-text-primary">
          <div
            v-if="isEditingName"
            data-tooltip-danger="Nome deve conter pelo menos 3 caracteres"
            data-tooltip-pos="up"
            :data-tooltip-visible="hasErrorName"
            data-tooltip-disable
          >
            <input
              ref="inputName"
              class="input input-name-shape input-name-color has-text-centered is-uppercase has-text-primary"
              :class="{ 'is-danger': hasErrorName }"
              v-model="$v.editingName.$model"
              @focus="onFocusName"
              @blur="onBlurName(editingName.toLowerCase())"
              @keydown.enter.stop.prevent="$refs.inputName.blur()"
            />
          </div>
          <span v-else class="is-uppercase name" @click="focusInputName">
            {{ moduleName }}
          </span>
        </div>
        <div class="description">
          <div
            v-if="isEditingDescription"
            :data-tooltip-danger="'A descrição não pode ser vazia'"
            data-tooltip-pos="up"
            :data-tooltip-visible="hasErrorDescription"
            data-tooltip-disable
          >
            <textarea
              ref="inputDescription"
              class="input input-description has-text-centered"
              :class="{ 'is-danger': hasErrorDescription }"
              v-model="$v.editingDescription.$model"
              @focus="onFocusDescription"
              @blur="onBlurDescription(editingDescription)"
              @keydown.enter.stop.prevent="$refs.inputDescription.blur()"
            ></textarea>
          </div>
          <span v-else @click="focusInputDescription">
            {{ moduleDescription }}
          </span>
        </div>
      </hero>
      <hero class="column">
        <div class="is-fullheight columns is-vcentered">
          <div class="column is-paddingless has-text-centered">
            <div class="title is-4">Instruções para atualização</div>
            <a
              target="_blank"
              href="https://confluence.fesctecnologia.com.br/display/S30FBEDES/Cli"
              class="button is-link"
              rel="noopener noreferrer"
            >
              Abrir documentação
            </a>
          </div>
        </div>
      </hero>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';
import { createNamespacedHelpers } from 'vuex';
import authorize from '@/views/auth/authorize';
import Hero from '@/components/Hero.vue';

const { mapGetters, mapActions } = createNamespacedHelpers('modules');
const { mapGetters: mapVersionsGetters, mapActions: mapVersionsActions } = createNamespacedHelpers('versions');

export default {
  name: 'module',
  mixins: [validationMixin],
  props: {
    module: {
      type: [Number, String],
    },
  },
  validations: {
    editingName: {
      required,
      maxLength: minLength(3),
    },
    editingDescription: {
      required,
    },
  },
  components: {
    Hero,
  },
  data() {
    return {
      isEditingName: false,
      isEditingDescription: false,
      editingName: '',
      editingDescription: '',
    };
  },
  async created() {
    await this.loadModule(this.module);
    this.loadVersions(this.module);
  },
  computed: {
    ...mapGetters(['currentModule']),
    ...mapVersionsGetters(['latestVersion']),
    moduleInfo() {
      return this.currentModule(this.module);
    },
    moduleName() {
      return this.moduleInfo && this.moduleInfo.name;
    },
    moduleDescription() {
      return this.moduleInfo && this.moduleInfo.description;
    },
    releaseCount() {
      return this.latestVersion && this.latestVersion.release + 1;
    },
    latestVersionSemver() {
      return this.latestVersion && this.latestVersion.version;
    },
    hasErrorName() {
      return this.$v.editingName.$error;
    },
    hasErrorDescription() {
      return this.$v.editingDescription.$error;
    },
  },
  methods: {
    ...mapActions(['loadModule', 'updateModule']),
    ...mapVersionsActions(['loadVersions']),
    openVersions(module) {
      this.$router.push(`/modules/${module}/versions`);
    },
    async updateModuleInfo(newModuleInfo) {
      await this.updateModule({
        id: this.moduleInfo.id,
        name: this.moduleName,
        description: this.moduleDescription,
        ...newModuleInfo,
      });
    },
    focusInputName() {
      if (authorize('module:write')) {
        this.isEditingName = true;
        this.$nextTick(() => {
          this.$refs.inputName.focus();
        });
      }
    },
    focusInputDescription() {
      if (authorize('module:write')) {
        this.isEditingDescription = true;
        this.$nextTick(() => {
          this.$refs.inputDescription.focus();
        });
      }
    },
    onFocusName() {
      this.editingName = this.moduleName;
    },
    onFocusDescription() {
      this.editingDescription = this.moduleDescription;
    },
    async onBlurName(newModuleName) {
      this.$v.editingName.$touch();
      if (!this.hasErrorName) {
        this.isEditingName = false;
        if (newModuleName !== this.moduleName) {
          await this.updateModuleInfo({ name: newModuleName });

          this.editingName = this.moduleName;
          this.$router.replace(`/modules/${newModuleName}`);
        }
      }
    },
    async onBlurDescription(newModuleDescription) {
      this.$v.editingDescription.$touch();
      if (!this.hasErrorDescription) {
        this.isEditingDescription = false;
        if (newModuleDescription !== this.moduleDescription) {
          await this.updateModuleInfo({ description: newModuleDescription });

          this.editingDescription = this.moduleDescription;
        }
      }
    },
  },
};
</script>

<style scoped>
.logo {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #2551881f;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: 500;
  margin: 0 auto;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.avatar {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.description {
  margin-top: 25px;
  width: fit-content;
  font-weight: 400;
  text-align: center;
  border: #0000 solid 1px;
}

.description:hover {
  border: #ddd solid 1px;
}

.name:hover {
  border: #eee solid 1px;
}

.content-icon {
  font-size: 32px;
}

.info {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-around;
  width: 60%;
  align-items: center;
}

.info-content {
  display: flex;
  justify-content: space-evenly;
}

.info-content > div:not(:first-of-type) {
  margin-left: 20%;
}

.info-hero {
  height: fit-content;
}

.button {
  width: 50%;
}

.input-name-shape {
  width: 100%;
  height: 100%;
  border-radius: 30%;
  font-size: inherit;
}

.input-name-color {
  background-color: #2551881f;
}

.input-description {
  width: 100%;
  height: 100%;
  resize: none;
}

.is-fullheight {
  height: 100%;
}
</style>
