import { api } from '@/infra/axios';

export const getAllVersions = async (moduleId, version = '') => {
  return api.get(`/modules/${moduleId}/versions${version ? `/${version}` : ''}`);
};

export const setVersionServices = async (moduleId, serviceVersions, spaVersions) => {
  return api.post(`/modules/${moduleId}/versions`, { serviceVersions: serviceVersions, spaVersions: spaVersions });
};

export const setVersionHotfixServices = async (versionId, serviceVersions, spaVersions) => {
  return api.post(`/versions/${versionId}/hotfix`, { serviceVersions: serviceVersions, spaVersions: spaVersions });
};

export const gelAllHotfixVersions = async (moduloId) => {
  return api.get(`modules/${moduloId}/versions/hotfix`);
};

export const getPublishedVersions = async (moduleId) => {
  return api.get(`/modules/${moduleId}/versions/published`, { authorization: false });
};

export const publishVersion = async (versionId, postData) => {
  return api.post(`/versions/${versionId}/publish`, postData);
};

export const getNextVersion = async (moduleId) => {
  return api.get(`/modules/${moduleId}/versions/next`);
};

export const getLatestVersion = async (moduleId) => {
  return api.get(`/modules/${moduleId}/versions/latest`);
};

export const getOpenVersions = async (moduleId) => {
  return api.get(`/modules/${moduleId}/versions/open`);
};

export const rejectVersion = async ({ versionId, motive }) => {
  return api.post(`/versions/${versionId}/reject`, { motive });
};

export const rejectHotfixVersion = async ({ versionId, motive }) => {
  return api.put(`/versions/${versionId}/reject/hotfix`, { motive });
};

export const checkHotfixActionIsEnable = async (versionId) => {
  return api.get(`/versions/${versionId}/isEnable/hotfix`);
};

export const enableNextVersionGeneration = (moduleId) => {
  return api.get(`/modules/${moduleId}/versions/isEnable/generate`);
};

export const getAllGenerateInfo = (versionId) => {
  return api.get(`/versions/${versionId}/generate`);
};

export const getAllGenerateNewVersionInfo = (moduleId) => {
  return api.get(`/modules/${moduleId}/versions/generate`);
};

export const getVersionById = async (versionId) => {
  const { data } = await api.get(`/version/${versionId}`);
  return data;
};
