import { api } from '@/infra/axios';

export const getCurrentEnvironmentsModuleVersion = async (moduleId) => {
  return api.get(`/environments/updates/${moduleId}`);
};

export const getEnvironmentsUpdates = async () => {
  return api.get(`/environments/updates`, { authorization: false });
};

export const getAllEnvironments = async () => {
  const { data } = await api.get('/environment');
  return data;
};

export const getEnabledEnvironments = async () => {
  const { data } = await api.get('/environment?enabled=true');
  return data;
};

export const getEnvironmentById = async (id) => {
  const { data } = await api.get(`/environment/${id}`);
  return data;
};

export const disableEnvironment = async (id) => {
  return api.delete(`/environment/${id}/disable`, null);
};

export const enableEnvironment = async (id) => {
  return api.put(`/environment/${id}/enable`);
};

export const saveEnvironment = async (environment) => {
  const { data } = await api.post('/environment', environment);
  return data;
};

export const updateEnvironment = async (id, environment) => {
  return api.put(`/environment/${id}`, environment);
};
