import store from '@/store';

import { refreshToken } from '@/services/api/authService';

const refreshAccessToken = async () => {
  const token = store.getters['auth/refreshToken'];

  try {
    const { data } = await refreshToken(token);

    const authData = {
      accessToken: data.access_token,
      refreshToken: data.refresh_token,
      user: data.user,
    };

    store.dispatch('auth/login', authData);
  } catch (err) {
    store.dispatch('auth/logout');
    responseErrorInterceptor(err);
  }
  global.refreshingToken = false;
  global.delayedRequests.forEach((request) => {
    request();
  });
};

export const requestInterceptor = (config) => {
  return new Promise((resolve) => {
    const executeRequest = async () => {
      const token = store.getters['auth/accessToken'];
      if (config.authorization !== false && token) {
        const header = Buffer.from(token.split('.')[0], 'base64').toString();
        const { expiresAt: expirationTime } = JSON.parse(header);
        const currentTime = new Date().toISOString();

        if (currentTime >= expirationTime) {
          global.refreshingToken = true;
          global.delayedRequests = [];
          await refreshAccessToken();
        }

        const accessToken = store.getters['auth/accessToken'];

        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      resolve(config);
    };

    if (global.refreshingToken) {
      global.delayedRequests.push(executeRequest);
    } else {
      executeRequest();
    }
  });
};

export const responseErrorInterceptor = (error) => {
  if (error.response.status === 401) {
    window.router.push({ name: 'denied' });
  }

  return Promise.reject(error);
};
