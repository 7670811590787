import {
  setTagInGitlab,
  setPipelineInGitlab,
  getLatestPipelineInGitlab,
  getSpaDependenciesListInGitlab,
} from '@/services/api/gitlabService';
import {
  setVersionServices,
  setVersionHotfixServices,
  getAllVersions as getAllVersionsInApi,
} from '@/services/api/versionsService';
import {
  getModule as getModuleApiService,
  getSpaModuleList as getSpaModuleListApiService,
  setSpaModules,
} from '@/services/api/modulesService';
import { getTags as getTagsInDocker, getDockerCatalog } from '@/services/api/dockerService';
import { getAllVersionServices } from '@/services/api/servicesService';
import { npmModuleVersions } from '@/services/api/npmService';

export const createNewTag = async (moduleId, version) => {
  const { data } = await setTagInGitlab(moduleId, version);
  return data;
};

export const createPipeline = async (moduleId, version) => {
  const { data } = await setPipelineInGitlab(moduleId, version);
  return data;
};

export const getModule = async (moduleId) => {
  const { data } = await getModuleApiService(moduleId);
  return data;
};

export const generateVersion = async (moduleId, services, spaVersions) => {
  const { data } = await setVersionServices(moduleId, services, spaVersions);
  return data;
};

export const generateHotfixVersion = async (versionId, serviceVersions, spaVersions) => {
  const { data } = await setVersionHotfixServices(versionId, serviceVersions, spaVersions);
  return data;
};

export const getTags = async (image) => {
  const url = new URL(`http://${image}`);
  const name = url.pathname;
  const { data } = await getTagsInDocker(name);
  return data;
};

export const getLatestTags = async (image, size = 10) => {
  const { tags } = await getTags(image);
  return tags
    .reverse()
    .filter((tag) => tag !== 'latest' && tag.indexOf('.') > -1)
    .splice(0, size);
};

export const getCatalog = async () => {
  const { data } = await getDockerCatalog();
  return data.map((image) => `docker.fesc.io/${image}`);
};

export const getAvailableServices = async () => {
  const { data } = await getAllVersionServices();
  return data;
};

export const getAllServices = async (moduleId, version) => {
  const { data } = await getAllVersionsInApi(moduleId, version);
  return data;
};

export const getLatestPipeline = async (moduleId, version) => {
  const { data } = await getLatestPipelineInGitlab(moduleId, version);
  return data;
};

export const getSpaDependenciesList = async () => {
  const { data } = await getSpaDependenciesListInGitlab();
  return data;
};

export const getNpmModuleVersions = async (moduleId, size = 10) => {
  const { data } = await npmModuleVersions(moduleId);
  return data.reverse().splice(0, size);
};

export const getSpaModuleList = async (moduleId, version) => {
  const { data } = await getSpaModuleListApiService(moduleId, version);
  return data;
};

export const insertSpaModule = async (moduleId, spaModules) => {
  const { data } = await setSpaModules(moduleId, spaModules);
  return data;
};
