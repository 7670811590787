<template>
  <div class="tables-versions-container">
    <table class="table is-fullwidth is-hoverable">
      <th>
        <h1><strong>Versões</strong></h1>
      </th>
      <tbody>
        <tr
          v-for="(
            {
              id,
              version,
              fix,
              releaseCandidate,
              versionNumber,
              refusalMotive,
              updatedAt,
              publishDetail,
              isEnabledHotFix,
            },
            index
          ) in versions"
          :key="index"
        >
          <td>
            <div class="level is-mobile" style="display: grid; grid-template-columns: 4fr 1fr">
              <div class="level-left" @click="toVersion(module, `${version}.${fix}-${releaseCandidate}`, id)">
                {{ `${version}.${fix}` }}
                <span v-if="!publishDetail.published" class="tag is-info release-tag">
                  Release Candidate: {{ releaseCandidate }}</span
                >
                <span v-if="publishDetail.published" class="release-number s-size-7 has-text-weight-semibold">
                  {{ releasesText(id) }}
                </span>
              </div>
              <div class="level-right">
                <span
                  v-for="{ environment, version: envVersion } in envVersion(versionNumber)"
                  :key="environment"
                  :data-tooltip="`${envVersion}`"
                  data-tooltip-pos="up"
                  class="tag is-info level-item is-hidden-mobile"
                >
                  Em {{ environment }}
                </span>
                <template v-if="publishDetail.published">
                  <div
                    v-if="versionPublisher[index]"
                    class="level-item publisher-avatar"
                    :data-tooltip="`Publicado por: ${versionPublisher[index].name}`"
                    data-tooltip-pos="up"
                  >
                    <user-avatar
                      :name="versionPublisher[index].name"
                      :email="versionPublisher[index].email"
                      style="max-width: 1.6rem"
                    />
                  </div>
                  <span
                    class="tag is-success level-item"
                    :data-tooltip="`Publicado em: ${publishedDate(updatedAt)}`"
                    data-tooltip-pos="right"
                    style="max-width: 5rem"
                  >
                    Publicada
                  </span>
                </template>
                <span v-else-if="refusalMotive" class="tag is-danger level-item"> Recusada </span>
                <div v-else class="level-item actions-container">
                  <button
                    v-if="authorize('version:reject')"
                    class="button is-danger is-small has-margin-right"
                    @click.stop="$emit('reject', { versionId: id })"
                  >
                    Recusar
                  </button>
                  <button
                    v-if="authorize('version:publish')"
                    class="button is-small"
                    @click.stop="
                      $emit('publish', {
                        versionId: id,
                        completeVersion: `${version}.${fix}-${releaseCandidate}`,
                      })
                    "
                  >
                    Publicar
                  </button>
                </div>
              </div>
            </div>
          </td>
          <div class="dropdown is-hoverable" v-if="authorize('version:generate') && isEnabledHotFix">
            <div class="dropdown-trigger">
              <button class="button is-small" aria-haspopup="true" aria-controls="dropdown-menu4">
                <span class="icon is-small">
                  <i class="fas fa-angle-down" aria-hidden="true"></i>
                </span>
              </button>
            </div>
            <div class="dropdown-menu" id="dropdown-menu4" role="menu">
              <div class="dropdown-content">
                <div class="dropdown-item is-flex">
                  <button
                    class="button is-danger is-medium custom-button"
                    @click="goToHotfixWizard(version, fix, releaseCandidate, id)"
                    data-tooltip="Gerar nova versão HOTFIX"
                    data-tooltip-pos="left"
                    :data-tooltip-disable="!publishDetail.published"
                  >
                    Gerar versão Hotfix
                  </button>
                </div>
              </div>
            </div>
          </div>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';

import { getAllModules } from '@/services/api/modulesService';
import { getModuleEnvironmentsVersion } from '@/services/api/kubernetesService';
import { getUserData } from '@/services/api/userService';

import authorize from '@/views/auth/authorize';

import UserAvatar from '@/components/UserAvatar';

export default {
  name: 'VersionsTable',
  components: {
    UserAvatar,
  },
  props: {
    versions: Array,
    module: [String],
  },
  data() {
    return {
      moduleId: null,
      environmentsVersion: null,
      versionPublisher: [],
    };
  },
  computed: {
    ...mapGetters('versions', ['publishedVersions', 'allVersionsToModule']),
  },
  async created() {
    const { data: modules } = await getAllModules();

    this.moduleId = modules.find((module) => module.name === this.module)?.id;

    const { data: environmentsVersion } = await getModuleEnvironmentsVersion(this.moduleId);

    this.environmentsVersion = environmentsVersion;

    const versionPublisher = this.publishedVersions.map(async ({ publishedBy }) => {
      if (publishedBy) {
        const { data } = await getUserData(publishedBy);

        return data;
      }
    });

    this.versionPublisher = await Promise.all(versionPublisher.reverse());
  },
  methods: {
    authorize,
    toVersion(module, version, versionId) {
      this.$router.push({
        name: 'versionServices',
        params: { module, version, versionId },
      });
    },
    releasesText(id) {
      const version = this.allVersionsToModule.find((v) => v.id == id);
      const sufixPlural = version.qtRelease > 1 ? 's' : '';

      return `${version.qtRelease} release${sufixPlural} liberada${sufixPlural}`;
    },
    publishedDate(date) {
      return dayjs(date).format('DD/MM/YYYY');
    },
    envVersion(version) {
      return (
        this.environmentsVersion?.filter(({ version: envVersion }) => {
          if (envVersion) {
            return envVersion.split('-')[0] === version.split('-')[0];
          }
        }) || []
      );
    },
    goToHotfixWizard(version, fix, release, versionId) {
      this.$router.push({
        name: 'hotfixWizard',
        params: {
          versionId,
          currentEditing: {
            version: version.split('-')[0],
            fix,
            release: release,
            versionId,
            moduleId: this.moduleId,
          },
        },
      });
    },
  },
};
</script>

<style scoped>
.publisher-avatar {
  height: 25px;
  width: 25px;
  font-size: 12px;
}

.release-tag {
  margin-left: 1em;
}

.release-number {
  padding-left: 15px;
  font-size: 0.8rem;
  color: #b3b3b3;
}

.has-margin-right {
  margin-right: 10px;
}

td {
  cursor: pointer;
}

.custom-button {
  font-size: 1rem;
}

.dropdown {
  margin-top: 0.4rem;
}

.actions-container {
  display: flex;
  justify-content: flex-end;
}
</style>
