import { api } from '@/infra/axios';

export const authentication = async (user, password) => {
  const data = global.encodeURI(`username=${user}&password=${password}&grant_type=password&client_id=uaa-client`);

  return api.post(`/oauth/token`, data, { authorization: false });
};

export const refreshToken = async (token) => {
  const postData = global.encodeURI(`refresh_token=${token}&grant_type=refresh_token&client_id=uaa-client`);

  return api.post(`/oauth/token`, postData, { authorization: false });
};
