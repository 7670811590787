<template>
  <footer class="footer has-text-centered has-background-transparent">
    <span class="has-text-grey-light has-text-weight-semibold">RELEASER</span>
    <p class="has-text-grey-light is-size-7">© {{ FullYear }}</p>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  computed: {
    FullYear() {
      return new Date().getFullYear();
    },
  },
};
</script>
<style lang="scss" scoped>
.has-background-transparent {
  background-color: transparent;
}

.footer {
  padding: 1em;
}
</style>
