<template v-if="showModal">
  <div class="modal" :class="{ 'is-active': showModal }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title has-text-weight-bold has-text-primary">{{ modalTitle }}</p>
        <button class="delete" aria-label="Fechar" @click="hideModal()"></button>
      </header>
      <section class="modal-card-body">
        <div class="notification is-danger is-light" v-if="errorMessage">
          <button class="delete" aria-label="Fechar" @click="hideMessageError()"></button>
          {{ errorMessage }}
        </div>
        <div class="columns" v-if="!showLoadingAnimation">
          <div class="column">
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">Módulo:</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <p class="control">
                    <input class="input is-static" type="text" :value="version.moduleName" readonly />
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="column">
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">Versão:</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <p class="control">
                    <input class="input is-static" type="text" :value="versionNumber" readonly />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <template v-if="firstStep && !showLoadingAnimation">
          <div class="field is-horizontal">
            <label class="label">Selecione o ambiente que será atualizado: </label>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Ambiente:</label>
            </div>
            <div class="field-body">
              <div class="select">
                <select v-model="environment">
                  <option v-bind:value="environment" v-for="environment in environments" :key="environment.id">
                    {{ environment.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </template>
        <template v-if="secondStep && !showLoadingAnimation">
          <div class="field">
            <label class="label">Os seguintes serviços serão atualizados no ambiente:</label>
          </div>
          <table class="table is-fullwidth is-hoverable">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Versão Atual</th>
                <th>Versão Nova</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(service, index) in result.allServices" :key="index">
                <td v-if="service.name">{{ service.name }}</td>
                <td v-if="service.lib">SPA - {{ service.lib }}</td>
                <td v-if="!service.error">{{ service.oldTag }}</td>
                <td v-if="!service.error">{{ service.tag }}</td>
                <td v-if="service.error">
                  <span class="icon-text has-text-danger">
                    <span class="icon">
                      <i class="fas fa-ban"></i>
                    </span>
                    <span class="has-tooltip-right" :data-tooltip="service.error.message.message">
                      serviço não localizado
                    </span>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </template>
        <template v-if="thirdStep && !showLoadingAnimation">
          <table class="table is-fullwidth is-hoverable">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Versão</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in result.allServices" :key="index">
                <td v-if="item.service">{{ item.service.name }}</td>
                <td v-if="item.spaLib">{{ item.spaLib }}</td>
                <td>{{ item.tag }}</td>
                <td v-if="isSuccess(item.status)">
                  <span class="icon-text has-text-success">
                    <span class="icon">
                      <i class="fas fa-check-square"></i>
                    </span>
                    <span>Success</span>
                  </span>
                </td>
                <td v-if="!isSuccess(item.status)">
                  <span class="icon-text has-text-danger">
                    <span class="icon">
                      <i class="fas fa-ban"></i>
                    </span>
                    <span>Failured</span>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </template>
        <template v-if="showLoadingAnimation">
          <div class="loading-rocket">
            <rocket-loading :completed="completedAnimation"></rocket-loading>
          </div>
        </template>
      </section>
      <template>
        <footer class="modal-card-foot">
          <div class="buttons" v-if="!showLoadingAnimation">
            <button class="button" @click="backToSelectEnv()" v-if="secondStep">Voltar</button>
            <button class="button is-success" @click="validate()" :disabled="!hasSelectedEnv()" v-if="firstStep">
              Avançar
            </button>
            <button class="button is-success" @click="confirm()" v-if="secondStep">Confirmar</button>
            <button class="button" @click="hideModal()" v-if="!thirdStep">Cancelar</button>
            <button class="button" @click="hideModal()" v-if="thirdStep">Fechar</button>
          </div>
        </footer>
      </template>
    </div>
  </div>
</template>

<script>
import RocketLoading from '@/components/RocketLoading';
import { getEnabledEnvironments } from '@/services/api/environmentsService';
import { getVersionById } from '@/services/api/versionsService';
import { preFlight, createAndExecute } from '@/services/api/planUpdateService';

const defaultErrorMessage = 'Erro, não foi possível processar sua solicitação!';

export default {
  name: 'planUpdateModal',
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    versionId: {
      type: [Number, String],
      required: true,
    },
  },
  components: {
    RocketLoading,
  },
  data() {
    return {
      errorMessage: null,
      showLoadingAnimation: false,
      completedAnimation: false,
      firstStep: true,
      secondStep: false,
      thirdStep: false,
      modalTitle: 'Atualizar Ambiente',
      environment: '',
      environments: [],
      version: '',
      versionNumber: '',
      result: '',
    };
  },
  async mounted() {
    await this.loadEnvironments();
    await this.loadVersion();
  },
  methods: {
    async loadEnvironments() {
      this.environments = await getEnabledEnvironments();
    },
    async loadVersion() {
      this.version = await getVersionById(this.versionId);
      this.versionNumber = this.version.version + '.' + this.version.fix + '-' + this.version.rc;
    },
    async validate() {
      this.showLoading();
      try {
        this.result = await preFlight({
          versionId: this.version.id,
          environmentId: this.environment.id,
        });
        this.hideLoading();
        this.showSecondStep();
      } catch (err) {
        this.showMessageError(err);
      }
    },
    async confirm() {
      this.showLoading();
      try {
        this.result = await createAndExecute({
          versionId: this.version.id,
          environmentId: this.environment.id,
        });
        await this.showCompletedAnimation();
        this.hideLoading();
        this.showThirdStep();
      } catch (err) {
        this.showMessageError(err);
      }
    },
    showLoading() {
      this.completedAnimation = false;
      this.showLoadingAnimation = true;
    },
    hideLoading() {
      this.showLoadingAnimation = false;
    },
    async showCompletedAnimation() {
      this.completedAnimation = true;
      const sleep = (t) => {
        return new Promise((resolve) => global.setTimeout(resolve, t));
      };
      await sleep(500);
    },
    showSecondStep() {
      this.firstStep = false;
      this.secondStep = true;
    },
    showThirdStep() {
      this.firstStep = false;
      this.secondStep = false;
      this.thirdStep = true;
    },
    hasSelectedEnv() {
      return this.environment;
    },
    backToSelectEnv() {
      this.thirdStep = false;
      this.secondStep = false;
      this.firstStep = true;
      this.showLoadingAnimation = false;
    },
    isSuccess(status) {
      return 'SUCCESS' == status;
    },
    hideModal() {
      this.$emit('hideModal');
      this.backToSelectEnv();
      this.hideMessageError();
    },
    showMessageError(err) {
      this.hideLoading();
      if (err) {
        this.errorMessage = err.response.data;
      } else {
        this.errorMessage = defaultErrorMessage;
      }
    },
    hideMessageError() {
      this.errorMessage = null;
    },
  },
};
</script>
<style scoped></style>
