import types from './types';

import { getModule, getAllModules, updateModule as updateModuleApiService } from '@/services/api/modulesService';

const loadModule = async ({ commit }, module) => {
  await getModule(module).then(({ data: moduleInfo }) => commit(types.SET_MODULE, moduleInfo));
};

const loadAllModules = async ({ commit }) => {
  await getAllModules().then(({ data: modules }) => commit(types.SET_ALL_MODULES, modules));
};

const updateModule = async ({ dispatch }, { id, ...module }) => {
  await updateModuleApiService(id, module);
  await dispatch('loadModule', id);
};

export default {
  loadModule,
  loadAllModules,
  updateModule,
};
