<template>
  <div>
    <hero>
      <h4 class="title has-text-weight-bold is-4 has-text-primary">Serviços</h4>
      <nav class="level is-mobile full-width">
        <div class="level-left"></div>
        <div class="level-right">
          <div class="level-item">
            <button
              :disabled="!allowInsert"
              @click="
                () => {
                  this.isEditing = !this.isEditing;
                  save();
                }
              "
              class="button is-primary is-pulled-right"
            >
              {{ isEditing ? 'Salvar' : 'Editar' }}
            </button>
          </div>
        </div>
      </nav>
      <table class="table is-fullwidth">
        <thead>
          <tr>
            <th>Nome</th>
            <th>Descrição</th>
            <th>Imagem</th>
          </tr>
        </thead>
        <tfoot>
          <tr :style="isEditing && allowInsert ? 'cursor: pointer;' : 'opacity: 0.5;cursor: not-allowed'">
            <th colspan="3" @click="newService"><i class="fas fa-plus" style="font-size: 0.9em"></i> Adicionar novo</th>
          </tr>
        </tfoot>
        <tbody>
          <tr v-for="(service, index) in services" :key="index">
            <td>
              <input class="input" v-model="service.name" v-if="service.editing" />
              <span v-else>{{ service.name }}</span>
            </td>
            <td>
              <input class="input" v-model="service.description" v-if="service.editing" />
              <span v-else>{{ service.description }}</span>
            </td>
            <td>
              <div class="field has-addons">
                <div class="control">
                  <div v-if="service.editing" class="select is-fullwidth">
                    <select v-if="service.editing" v-model="service.image" :disabled="!service.editing">
                      <option v-for="option in unusedCatalog" :key="option" dir="rtl">{{ option }}</option>
                    </select>
                  </div>
                  <span v-else>{{ service.image }}</span>
                </div>
                <p class="control">
                  <span v-if="service.editing" @click="removeService(index)" class="icon is-medium">
                    <i class="fas fa-times" />
                  </span>
                </p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </hero>
  </div>
</template>

<script>
import Hero from '@/components/Hero';

import { getAllVersionServices, setService } from '@/services/api/servicesService';
import { getDockerCatalog } from '@/services/api/dockerService';

export default {
  name: 'Services',
  components: {
    Hero,
  },
  data() {
    return {
      services: [],
      catalog: [],
      isEditing: false,
    };
  },
  created() {
    this.loadServices();
    this.loadCatalog();
  },
  computed: {
    allowInsert() {
      return this.services && !this.services.some(({ name, description, image }) => !(name && description && image));
    },
    unusedCatalog() {
      return this.catalog.filter(
        (dockerImage) =>
          !this.services
            .filter(({ editing }) => !editing)
            .map(({ image }) => image)
            .includes(dockerImage),
      );
    },
  },
  methods: {
    async loadServices() {
      const { data } = await getAllVersionServices();
      this.services = data;
    },
    newService() {
      if (this.allowInsert && this.isEditing)
        this.services.push({ name: '', description: '', image: '', editing: true });
    },
    async loadCatalog() {
      const { data } = await getDockerCatalog();
      this.catalog = data.map((image) => `docker.fesc.io/${image}`);
    },
    async save() {
      const servicesToSave = this.services
        .filter(({ name, description, image, editing }) => editing && name && description && image)
        .map(({ ...service }) => service);

      const insertions = servicesToSave.map(async (service) => {
        await setService(service);
      });

      await Promise.all(insertions);

      this.loadServices();
    },
    removeService(index) {
      this.services.splice(index, 1);
    },
  },
};
</script>

<style scoped>
.icon {
  cursor: pointer;
}
</style>
