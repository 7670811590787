<template>
  <div class="modal is-active">
    <div class="modal-background" @click="$emit('close')"></div>
    <div class="modal-card">
      <header class="modal-card-head has-text-primary has-text-weight-bold is-size-5">Recusar versão</header>
      <section class="modal-card-body">
        <div class="field">
          <p class="label">Descreva algum motivo para recusar a versão:</p>
          <textarea
            class="textarea has-fixed-size"
            :class="{ 'is-danger': $v.refusalMotive.$error }"
            rows="2"
            @keyup.enter.exact="reject()"
            @keydown.enter.prevent
            v-model.trim="$v.refusalMotive.$model"
          ></textarea>
          <p class="help is-danger" v-show="$v.refusalMotive.$error">
            <template v-if="!$v.refusalMotive.required"> A descrição do motivo não pode ser vazia </template>
            <template v-if="!$v.refusalMotive.minLength">
              A descrição do motivo deve conter 60 ou mais caracteres
            </template>
            <template v-if="!$v.refusalMotive.maxLength">
              A descrição do motivo deve conter 240 ou menos caracteres
            </template>
          </p>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-danger" @click="reject()" :disabled="$v.refusalMotive.$error">Recusar</button>
        <button class="button" @click="$emit('close')">Cancelar</button>
      </footer>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, maxLength, minLength } from 'vuelidate/lib/validators';

export default {
  name: 'RejectVersion',
  mixins: [validationMixin],
  data() {
    return {
      refusalMotive: null,
    };
  },
  validations: {
    refusalMotive: {
      required,
      minLength: minLength(60),
      maxLength: maxLength(240),
    },
  },
  methods: {
    reject() {
      if (!this.$v.refusalMotive.$invalid) {
        this.$emit('reject', this.refusalMotive);
        this.$emit('close');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  z-index: 600;

  &-card {
    width: 40%;
  }

  &-card-head {
    background-color: #ffffff;
    padding: 0.7em;
    padding-bottom: 0.1em;
    border: none;
  }

  &-card-body {
    padding: 0.85em;
  }

  &-card-foot {
    background-color: #ffffff;
    padding: 0.85em;
    padding-top: 0.2em;
    border: none;
    justify-content: flex-end;
  }
}
</style>
