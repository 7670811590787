const axios = require('axios');
const { requestInterceptor, responseErrorInterceptor } = require('./interceptor');

const api = axios.create({
  baseURL: process.env.VUE_APP_RELEASER_API_URL,
});

api.interceptors.request.use(requestInterceptor, (error) => Promise.reject(error));

api.interceptors.response.use((response) => response, responseErrorInterceptor);

export { api };
