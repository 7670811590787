<template>
  <hero>
    <rocket-loading v-if="loading" :completed="generatedVersion" />
    <div class="version-content" v-else>
      <div style="margin-bottom: 1rem">
        <button class="button" @click="$router.go(-1)" data-tooltip="Página anterior" data-tooltip-pos="right">
          <i class="fas fa-arrow-left"></i>
        </button>
      </div>
      <div class="version-header">
        <div class="is-pulled-right">
          <div
            data-tooltip-pos="up"
            data-tooltip="Não existem ambientes com esse módulo"
            :data-tooltip-disable="environmentsVersion.length > 0"
            style="display: inline"
          >
            <div class="select is-hidden-mobile" :class="{ 'is-loading': envSelectLoading }" style="margin-right: 10px">
              <select :disabled="!environmentsVersion.length || envSelectLoading" @change="onChangeEnvironment">
                <option disabled selected hidden>Mostrar versões no ambiente</option>
                <option v-for="({ environment }, index) in environmentsVersion" :value="index" :key="index">
                  {{ environment }}
                </option>
              </select>
            </div>
          </div>
          <div
            data-tooltip-pos="left"
            data-tooltip-length="medium"
            data-tooltip="Não é possivel criar uma nova versão se não houver nenhuma alteração de tag"
            :data-tooltip-disable="!editing && allowsGenerate"
            style="display: inline"
          >
            <button
              :disabled="!authorize('version:generate') || editing || !allowsGenerate"
              @click="openModalToConfirmation()"
              class="button is-success"
            >
              Gerar versão
            </button>
          </div>
        </div>
        <p class="title is-3">
          <template v-if="version">
            <template>
              <span>{{ this.oldVersionString }}</span>
              <font-awesome-icon :icon="['fa', 'arrow-right']" class="icon" />
              <span>{{ this.nextVersion }}</span>
            </template>
          </template>
          <template v-else>
            {{ nextVersion }}
          </template>
        </p>

        <p v-if="this.isHotfix" class="subtitle is-5">
          Gerar nova versão <strong style="color: #f43e61">HOTFIX</strong> para módulo '{{ module }}'
        </p>
        <p v-else class="subtitle is-5">
          {{ `Gerar nova versão para módulo '${module}'` }}
        </p>
      </div>
      <div class="version-warning message is-info" v-if="moduleInfo.disableServiceDowngrade">
        <div class="message-body">
          Esse módulo não permite que serviços tenham sua versão alterada para uma versão inferior a definida atualmente
        </div>
      </div>
      <div class="version-warning message is-info" v-if="errorOnCreateVersion">
        <div class="message-body">
          {{ errorMessage }}
        </div>
      </div>
      <div class="version-table">
        <nav class="level is-mobile">
          <div class="level-left"></div>
          <div class="level-right">
            <button
              @click="editingServices"
              class="button is-primary"
              :disabled="!authorize('version:generate') || (editing && !allowsInsert)"
            >
              {{ editing ? 'Salvar' : 'Editar' }}
            </button>
          </div>
        </nav>
        <div class="table-container">
          <table class="table is-fullwidth">
            <thead>
              <tr>
                <th>Microserviço</th>
                <th>Tag</th>
              </tr>
            </thead>
            <tfoot v-if="editing">
              <tr :style="allowsInsert ? 'cursor: pointer;' : 'opacity: 0.5;cursor: not-allowed'">
                <th
                  :class="{ 'new-service': allowsInsert }"
                  colspan="2"
                  @click="
                    () => {
                      !allowsInsert || newService();
                    }
                  "
                >
                  <i class="fas fa-plus" style="font-size: 0.9em"></i> Adicionar novo
                </th>
              </tr>
            </tfoot>
            <tbody>
              <tr
                v-for="({ image, description, tag, tags, definedTag, initialDefinedTag }, index) in services"
                :key="index"
              >
                <td v-if="image">
                  <div class="service-info">
                    <div class="service-info__left">
                      <span class="tag is-success" v-if="!tag"> Novo </span>
                      <div>
                        <p>{{ description }}</p>
                        <p class="is-size-7">{{ image }}</p>
                      </div>

                      <template v-if="envServiceVersions">
                        <span
                          data-tooltip-pos="up"
                          data-tooltip="Clique para definir essa versão como tag atual"
                          :data-tooltip-disable="!editing || moduleInfo.disableServiceDowngrade"
                          style="margin-left: 3px; cursor: unset"
                          class="tag is-info"
                          :class="{
                            'env-version-tag': editing && !moduleInfo.disableServiceDowngrade,
                          }"
                          v-if="envServiceVersions[image]"
                          @click="changeTagByEnv(index, envServiceVersions[image])"
                        >
                          {{ envServiceVersions[image] }}
                        </span>
                        <span
                          data-tooltip-pos="up"
                          data-tooltip="Esse microserviço não foi encontrado no ambiente escolhido"
                          style="margin-left: 3px"
                          class="tag is-danger"
                          v-else
                        >
                          Não encontrado
                        </span>
                      </template>
                    </div>

                    <div>
                      <button
                        v-if="image == 'docker.fesc.io/sgusuite/spa' && validSpaVersion(definedTag)"
                        class="button is-small is-info"
                        @click="showSpaModal = true"
                      >
                        Módulos
                      </button>
                      <div v-show="tag && tag != definedTag" class="has-text-weight-semibold image-tag">
                        <span>{{ tag }}</span>
                        <font-awesome-icon :icon="['fa', 'arrow-right']" class="icon" />
                      </div>
                    </div>
                  </div>
                </td>
                <td v-else>
                  <div class="select is-fullwidth">
                    <select v-model="image" @change="changeImage(index, image)" :disabled="!editing">
                      <option v-for="option in unrelatedServices" :key="option">
                        {{ option }}
                      </option>
                    </select>
                  </div>
                </td>
                <td>
                  <div class="field has-addons">
                    <div class="control select-tags-container">
                      <div class="select tag-select">
                        <select
                          class="tag-select__select"
                          v-model="definedTag"
                          @change="changeTag(index, definedTag)"
                          :disabled="!editing || !tags"
                        >
                          <option v-for="option in validTags(tags, initialDefinedTag)" :key="option">
                            {{ option }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <p class="control">
                      <span v-if="!tag" @click="removeService(index)" class="icon is-medium">
                        <i class="fas fa-times" />
                      </span>
                    </p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <spa-modal
      v-if="spaModules"
      :edit-enabled="editing"
      :show.sync="showSpaModal"
      :input-modules="spaModules"
      @newModules="insertSpaModules"
    ></spa-modal>
    <modal-confirm
      v-if="showModalConfirm"
      :show.sync="showModalConfirm"
      :version="getVersionToConfirm()"
      :nextVersion="firstRelease ? `${nextVersion}-0` : nextVersion"
      :actionString="'Gerar nova versão'"
      @generateNewVersionEvent="generateNewVersion"
    />
  </hero>
</template>

<script>
import semver from 'semver';
import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';

import SpaModal from './spaModal';
import Hero from '@/components/Hero';
import RocketLoading from '@/components/RocketLoading';
import ModalConfirm from '@/components/ModalConfirm.vue';

import {
  generateVersion,
  generateHotfixVersion,
  getLatestTags,
  getAvailableServices,
  getCatalog,
  getSpaModuleList,
} from '@/services/versions';
import { getAllGenerateInfo, getAllGenerateNewVersionInfo } from '@/services/api/versionsService';

import authorize from '@/views/auth/authorize';

import { getAllModules } from '@/services/api/modulesService';
import { getModuleEnvironmentsVersion } from '@/services/api/kubernetesService';
import { getAllServicesToVersion } from '@/services/api/servicesService';

export default {
  name: 'firstStep',
  components: {
    Hero,
    RocketLoading,
    SpaModal,
    ModalConfirm,
  },
  props: {
    module: [Number, String],
  },
  data() {
    return {
      version: null,
      fix: null,
      release: null,
      nextVersion: null,
      versionId: null,
      services: [],
      allowsInsert: false,
      currentEditingVersion: null,
      currentEditingRelease: null,
      currentEditingFix: null,
      published: null,
      loading: true,
      editing: false,
      generatedVersion: false,
      availableServices: null,
      catalog: null,
      spaLowestVersion: '1.0.155-640',
      showSpaModal: null,
      spaModules: null,
      newSpaModules: null,
      updateSpa: null,
      moduleId: null,
      envSelectLoading: false,
      envServiceVersions: null,
      environmentsVersion: [],
      moduleInfo: null,
      showModalConfirm: false,
      errorOnCreateVersion: false,
      errorMessage: null,
    };
  },
  watch: {
    $route: {
      handler() {
        this.loading = true;
        this.load().then(() => {
          this.loading = false;
        });
      },
      immediate: true,
    },
  },
  computed: {
    unrelatedServices() {
      const services = this.services.map(({ image }) => image);
      const availableServices = this.availableServices.map(({ image }) => image);

      return availableServices.filter((image) => !services.includes(image));
    },
    allowsGenerate() {
      const changedServices = this.services.filter(({ tag, definedTag }) => tag !== definedTag || !tag);
      return changedServices.length > 0 || this.updateSpa;
    },
    newVersion() {
      return !this.version ? '3.0.0' : semver.inc(this.version, 'patch');
    },
    isHotfix() {
      return this.$route?.meta?.isHotfix;
    },
    versionIdHotfix() {
      return this.$route?.params?.versionId;
    },
    firstRelease() {
      return this.nextVersion.split('-').length === 1;
    },
    oldVersionString() {
      return `${this.version}.${this.fix}-${this.release ? `${this.release}` : '0'}`;
    },
  },
  methods: {
    authorize,
    async load() {
      this.versionIdHotfix ? await this.startInfosToNewHotfixVersion() : await this.startInfosToNewVersion();

      await this.setSPAModulesList();

      this.services && (await this.setTagsToService());

      const catalog = await getCatalog();
      this.catalog = catalog;

      const availableServices = await getAvailableServices();
      this.availableServices = availableServices;

      await this.setModuleInfos();
      await this.setEnvironmentsToVersion();
    },
    async startInfosToNewVersion() {
      await getAllGenerateNewVersionInfo(this.module).then(async ({ data: versionInfos }) => {
        this.setInitialGoblalyValues(versionInfos);
      });
    },
    async startInfosToNewHotfixVersion() {
      this.versionId = this.versionIdHotfix;

      await getAllGenerateInfo(this.versionIdHotfix).then(async ({ data: versionInfos }) => {
        this.setInitialGoblalyValues(versionInfos);
      });
    },
    setInitialGoblalyValues(versionInfos) {
      const { nextVersion, services, version: currentVersion } = versionInfos;
      const { version, fix, release, published, id } = currentVersion;

      if (currentVersion) {
        this.version = version;
        this.fix = fix;
        this.release = release;
        this.published = published;
        this.versionId = id;
      }

      this.nextVersion = nextVersion;
      this.services = services;
    },
    async setSPAModulesList() {
      const versionConpleteString = `${this.version}.${this.fix}-${this.release}`;

      await getSpaModuleList(this.module, versionConpleteString)
        .then((spaModules) => {
          this.spaModules = spaModules.map(({ lib, tag }) => ({ lib, tag }));
          this.newSpaModules = _cloneDeep(this.spaModules);
        })
        .catch(() => {
          this.spaModules = [];
          this.newSpaModules = [];
        });
    },
    async setTagsToService() {
      // eslint-disable-next-line no-unused-vars
      for (const service of this.services) {
        const tags = await getLatestTags(service.image);

        service.tags = tags;
        service.definedTag = service.tag;

        if (!service.tags.includes(service.tag)) service.tags.push(service.tag);
      }

      this.services = this.services.map((service) => ({
        ...service,
        initialDefinedTag: service.definedTag,
      }));
    },
    async setModuleInfos() {
      const { data: modules } = await getAllModules();

      if (typeof this.module === 'string') this.moduleId = modules.find((module) => module.name === this.module)?.id;
      else this.moduleId = this.module;

      const { data: moduleInfo } = await getAllModules(this.moduleId);
      this.moduleInfo = moduleInfo;
    },
    async setEnvironmentsToVersion() {
      const { data: environmentsVersion } = await getModuleEnvironmentsVersion(this.moduleId);

      this.environmentsVersion = environmentsVersion.filter((env) => env.version);
    },
    async generateNewVersion() {
      this.generatedVersion = false;
      this.loading = true;
      this.errorMessage = null;

      const changedServices = this.services
        .filter(({ tag, definedTag }) => tag !== definedTag || !tag)
        .map((service) => ({
          serviceId: service.id,
          tag: service.definedTag,
        }));

      try {
        const result = this.isHotfix
          ? await generateHotfixVersion(this.versionId, changedServices, this.newSpaModules)
          : await generateVersion(this.module, changedServices, this.newSpaModules);

        this.versionId = result.versionId;
        this.generatedVersion = true;
        this.loading = false;

        const params = {
          module: this.module,
          version: this.nextVersion,
          versionId: this.versionId,
        };

        this.$router.replace({ name: 'versionServices', params });
      } catch (err) {
        this.editing = !this.editing;
        this.showErrorMessage(err.response.data);
      }
    },
    showErrorMessage(data) {
      if (data.message.includes('downgrade')) {
        this.moduleInfo.disableServiceDowngrade = true;
      } else {
        this.errorMessage = data.message;
        this.errorOnCreateVersion = true;
      }
      this.generatedVersion = true;
      this.loading = false;
    },
    newService() {
      this.services.push({ loading: false, loaded: false });
      this.checkAllowsInsert();
    },
    editingServices() {
      this.editing = !this.editing;
      this.checkAllowsInsert();
    },
    removeService(index) {
      this.services.splice(index, 1);
      this.checkAllowsInsert();
    },
    changeTag(index, tag) {
      if (this.editing) {
        this.services[index].definedTag = tag;
        this.checkAllowsInsert();
      }
    },
    changeTagByEnv(index, tag) {
      if (!this.moduleInfo.disableServiceDowngrade) {
        this.changeTag(index, tag);
      }
    },
    async changeImage(index, image) {
      const service = this.services[index];
      service.loading = true;
      service.image = image;
      service.id = this.availableServices.find((service) => service.image === image).id;
      const tags = await getLatestTags(image);
      service.tags = tags;
      service.loading = false;
      service.loaded = true;
      this.checkAllowsInsert();
    },
    checkAllowsInsert() {
      const services = this.services;
      const length = services.length;
      if (length > 0) {
        const service = services[length - 1];
        this.allowsInsert = !!service.image;
      } else {
        this.allowsInsert = true;
      }
    },
    validSpaVersion(version) {
      return version && semver.gte(version, this.spaLowestVersion);
    },
    validTags(tags, definedTag) {
      if (this.moduleInfo.disableServiceDowngrade && tags && definedTag) {
        return tags.filter((tag) => semver.gte(tag, definedTag));
      }

      return tags;
    },
    insertSpaModules(newModules) {
      this.newSpaModules = newModules.map(({ lib, tag }) => ({ lib, tag }));
      if (!_isEqual(this.newSpaModules, this.spaModules)) {
        this.updateSpa = true;
      }
    },
    async onChangeEnvironment(event) {
      this.envSelectLoading = true;
      try {
        const environment = this.environmentsVersion[parseInt(event.target.value)];

        const { data } = await getAllServicesToVersion({
          moduleId: this.moduleId,
          version: environment.version,
          hotfix: this.isHotfix ? this.currentEditingFix : this.fix,
          release: this.isHotfix ? this.currentEditingRelease : this.release,
        });

        this.envServiceVersions = {};
        data.forEach(({ image, tag }) => (this.envServiceVersions[image] = tag));
      } finally {
        this.envSelectLoading = false;
      }
    },
    openModalToConfirmation() {
      this.showModalConfirm = true;
    },
    getVersionToConfirm() {
      const version = this.version;
      const release = this.release;
      const fix = this.fix;

      return release ? `${version}.${fix}-${release}` : `${version}.${fix}-0`;
    },
  },
};
</script>

<style lang="scss" scoped>
.service-info {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 36rem;

  & .service-info__left {
    display: flex;
    align-items: center;
    margin-right: 1em;

    & > * {
      &:not(:last-child) {
        margin-right: 1em;
      }
    }
  }
}

.version-content {
  display: flex;
  flex-direction: column;

  .version-header {
    margin-bottom: 1rem;
  }

  .version-table {
    margin-top: 1rem;
  }

  .version-warning {
    margin-bottom: 0;
  }
}

.select-tags-container {
  display: flex;
  width: 100%;
  min-width: 13rem;

  .tag-select {
    flex: 1;

    select {
      width: 100%;
    }
  }
}

.image-tag {
  display: flex;
  align-items: center;

  padding-left: 1rem;
  flex-shrink: 0;

  .icon {
    margin-left: 1rem;
  }
}

.icon {
  cursor: pointer;
}

.has-transparent-background {
  background-color: transparent;

  &:hover {
    background-color: transparent;
  }
  &:active {
    background-color: transparent;
  }
}
.table {
  td {
    vertical-align: middle;
  }
}
.new-service {
  cursor: pointer;

  &:hover {
    background: #efefef;
  }
}

.env-version-tag {
  cursor: pointer !important;
}
</style>
