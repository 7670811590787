<template>
  <div class="has-background-grayish">
    <div class="navbar is-primary header-container">
      <div class="navbar-menu">
        <figure class="image">
          <img
            @click="$router.push({ name: 'modules' })"
            src="../assets/zitrus_logo.png"
            alt="Logo da Zitrus Healthtech"
          />
        </figure>
      </div>
      <div class="navbar-menu title-container">
        <p class="title is-3">Releaser</p>
      </div>
      <div class="navbar-menu">
        <div class="navbar-end is-pulled-right is-fullheight">
          <div class="navbar-item" v-if="!username">
            <button class="button is-arrowless is-primary is-medium" @click="login()">Login</button>
            <template v-if="loginAlert">
              <div class="login"></div>
              <div class="login-arrow has-text-danger">
                <i class="fas fa-arrow-up fa-3x"></i>
              </div>
            </template>
          </div>
          <div class="dropdown is-right is-hoverable is-fullheight is-medium" v-else>
            <div class="dropdown-trigger header-avatar-container">
              <template>
                {{ username }}
              </template>
              <user-avatar class="user-avatar" :name="username" :email="email" />
            </div>
            <div class="dropdown-menu">
              <div class="dropdown-content">
                <div class="dropdown-item">
                  <a class="navbar-item logout-content" @click="logout"
                    >Logout<font-awesome-icon :icon="['fa', 'arrow-right']" class="icon"
                  /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <login :show.sync="showLogin"></login>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Login from '@/views/auth/Login';
import UserAvatar from '@/components/UserAvatar';

export default {
  name: 'Header',
  components: {
    Login,
    UserAvatar,
  },
  props: {
    eventHub: null,
  },
  data() {
    return {
      showLogin: false,
      loginAlert: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['username', 'email']),
  },
  mounted() {
    if (this.eventHub) this.eventHub.$on('toggleLogin', this.toggleLogin);
  },
  methods: {
    login() {
      this.showLogin = true;
      this.loginAlert = false;
    },
    logout() {
      this.$store.dispatch('auth/logout');
    },
    toggleLogin(value) {
      if (!this.username) {
        this.loginAlert = value;
      }
    },
  },
};
</script>

<style>
:root {
  --navbar-height: 72px;
}
</style>

<style lang="scss" scoped>
@import '~@/themes/variables.scss';
@import '~bulma/sass/utilities/all';

.header-container {
  padding: 0.5rem;

  justify-content: space-between;
}

.image {
  max-width: 115px;
  cursor: pointer;
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    color: $white-ter;
  }
}

.header-avatar-container {
  display: flex;
  align-items: center;
  cursor: pointer;

  margin-right: 1.7rem;
}

.logout-content {
  display: flex;
  justify-content: space-between;
}

.is-fullheight {
  height: 100%;
}

.user-avatar {
  width: 30px;
  height: 30px;
}

@keyframes arrow {
  50% {
    top: 80px;
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.login {
  border: 3px $danger solid;
  width: 100%;
  height: 100%;
  left: 0px;
  position: absolute;
  animation: blink 1000ms infinite linear;

  &-arrow {
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    top: 50px;
    left: 0px;
    animation: arrow 1000ms infinite linear;
  }
}

.navbar {
  z-index: 500;
  height: var(--navbar-height);
  display: flex;
}

.navbar-left-menu {
  width: 50%;
}

.navbar-brand {
  width: 50%;
  @include tablet {
    margin-left: 20%;
  }
}

.logo {
  --dimension: 25px;

  fill: #fff;
  width: var(--dimension);
  height: var(--dimension);
  margin-top: 8px;
  margin-right: 10px;
  cursor: pointer;
}

.module-name {
  transform: scaleY(0.9);
  font-weight: 900;
  cursor: pointer;
}

.has-background-grayish {
  background-color: #eff4fa;
}

.section {
  margin: 0 auto;
  max-width: 60vw;
  min-height: calc(100vh - var(--navbar-height));
}
</style>
