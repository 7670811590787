import Vue from 'vue';
import Router from 'vue-router';
import Application from '../views/Application';
import routes from './routes';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: Application,
      children: routes,
    },
  ],
});

window.router = router;

export default router;
